import { Component, OnInit,ElementRef, ViewChild,ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatInput } from '@angular/material';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
 import {FormsModule,} from '@angular/forms';
 import * as $ from "jquery";
 import { FormBuilder , FormGroup, Validators, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  regF:FormGroup; 
  rForm = {
    password:'',
    username:'',
    vType:''
   
  }
  message: string = 'Snack Bar opened.';
  //actionButtonLabel: string = 'Retry';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  addExtraClass: boolean = false;
  // @ViewChild(MatInputModule) MatInputModule:MatInputModule
  constructor(private _ser:BaseapiService,private _r:Router,private _fb:FormBuilder, private _ar: ActivatedRoute,public snackBar: MatSnackBar) { 
    this.regF = this._fb.group({
      // FullName:['',[Validators.required,Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
      // Email:['',[Validators.required,Validators.pattern("[a-zA-Z0-9]{3,}[@]{1}[a-zA-Z0-9]{3,}[.]{1}[a-zA-Z0-9]{2,}")]],
      // MobileNo:['',[Validators.required,Validators.pattern("[0-9]{10}")]],
      // State:['',[Validators.required]],
      password:['',[]],
      username:['',[]],
      vType:['pv',[]]
    })
  }
 res;
 device_id=Math.floor(Math.random()*90000000) + 10000000;
  ngOnInit() {
    this._r.navigate(['/']);
  //  alert(this._r.url)
    // if(localStorage.getItem('id')==null){
    //   this._ser.changeUrl('false');
    //   // this._r.navigate(['/']);
    // }else{
    //   this._ser.changeUrl('true');
    //   //this._r.navigate(['notification'])
    // }
    console.log(localStorage.getItem('device_id'))
    // this._ser.changeUrl('false');
    // 
  }
  ngAfterViewChecked(){
    //this._ser.changeUrl('false');
  }
  login(data){
    data["device_id"]=""+this.device_id+""; 
    data["app_version"]="0.1";
    if(data['vType']=='cv'){
      //data["app_name"]="com.tatamotors.cvcustomercare" ;
      data["app_name"]="customerapp.cv.portal" ;
    }
    else if(data['vType']=='pv'){
      //data["app_name"]="com.ttl.customersocialapp" ;
      data["app_name"]="customerapp.pv.portal" ;
    }
    let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
   
    if (this._ar.snapshot.params.id == undefined) {
     if(data["username"]==''){
      this.message="Enter Username"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
       //alert("Enter Username")
       return
     }
     if(data["password"]==''){
      this.message="Enter Password"
      this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
      return
    }
    
     
      this._ser.login(data).subscribe(
        res => {
          // alert(res)
          this.setResult(res);
          localStorage.setItem("access_token",res['token']['access_token'])
          localStorage.setItem("device_id",data["device_id"])
          localStorage.setItem("app_name",data["app_name"])
          this._r.navigate(['/notification']); 
         
        },
        error => {
          // console.log('res')
          // console.log(JSON.stringify(error))
          if (error.status == 412) {
            //this.stEerrormsg = error.error.detail
            this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
            //alert(error.error.msg)
            this._r.navigate(['/login']);
          }
          // this.setResult(error);
        }
      );
    }
    //  else {
    //   data['id']=this._ar.snapshot.params.id;
    //   this._ser.update(data).subscribe(
    //     res => {
    //       //console.log("Inner", res);
    //       this.setResult(res);
    //     },
    //     error => {
    //       this.setResult(error);
    //     }
    //   );
    // }
    ///console.log(form.value);
}
setResult(res) {
  this.res = res;
  // alert(JSON.stringify(res))
  //$("#msgModal").modal('show');
  // $("#msgModal").fadeIn(3000, function() {
  //   this.closeModel();
  // });
  // $('#msgModal').model('show');
   //console.log(JSON.stringify(res));
}
open() {
  let config = new MatSnackBarConfig();
  config.verticalPosition = this.verticalPosition;
  config.horizontalPosition = this.horizontalPosition;
  config.duration = this.setAutoHide ? this.autoHide : 0;
 // config.extraClasses = this.addExtraClass ? ['test'] : undefined;
  this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
}

}
