import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgModule } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatPaginator, MatTableDataSource, MatInput } from '@angular/material';
// import {
//   MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
//   MatSortModule, MatTableModule, MatSort
// } from "@angular/material";
import {
	MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
	MatSortModule, MatTableModule, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from "@angular/material";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PageEvent } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule, } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
@Component({
	selector: 'app-generic-feedback',
	templateUrl: './generic-feedback.component.html',
	styleUrls: ['./generic-feedback.component.css']
})
export class GenericFeedbackComponent implements OnInit {
	length = 0;
	pageSize = 50;
	// pageSizeOptions: number[] = [5, 10, 25, 100];
	pageSizeOptions: number[] = [50];

	// MatPaginator Output
	pageEvent: PageEvent;
	dataSource;
	displayedColumns = [];
	psfdata: any = '';
	message: string = 'Snack Bar opened.';
	//actionButtonLabel: string = 'Retry';
	actionButtonLabel: string = '';
	action: boolean = true;
	setAutoHide: boolean = true;
	autoHide: number = 2000;
	horizontalPosition: MatSnackBarHorizontalPosition = 'center';
	verticalPosition: MatSnackBarVerticalPosition = 'top';

	addExtraClass: boolean = false;

	@ViewChild(MatSort, {static: true}) sort: MatSort;

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


	columnNames = [
		{
			id: "id",
			value: " Id"
		},
		{
			id: "created_dt",
			value: "Created At"
		},
		{
			id: "created_by",
			value: "Created By"
		},

		{
			id: "updated_dt",
			value: "Updated Date"
		},
		{
			id: "updated_by",
			value: "Updated By"
		},
		{
			id: "user",
			value: "User Id"
		},
		{
			id: "fb-area",
			value: "Feedback Type"
		},
		{
			id: "img_list",
			value: "Image List"
		},
		{
			id: "user_remarks",
			value: "User Remarks"
		},
		{
			id: "status",
			value: "Status"
		},
		{
			id: "tml_remarks",
			value: "TML Remarks"
		}

	];
	public myDate: IMyDate = {

		year: this.getCurrentDay('Y'),
		month: this.getCurrentDay('M'),
		day: this.getCurrentDay('D'),

	}


	getCurrentDay(cat): any {
		let output;
		var today = new Date();


		var day = today.getDate();
		if (day != 1) {
			day = day - 1;
		}

		var month = today.getMonth() + 1; //January is 0!
		var year = today.getFullYear();

		if (cat === 'D') {
			output = day;
		}
		else if (cat === 'M') {
			output = month;
		}
		else {
			output = year;
		}
		return output;
	}
	public myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'mm/dd/yyyy',
		markCurrentDay: true,
	};
	jc_to_date: any = '';
	feedback_from_date: any = 'MM/DD/YYYY';
	feedback_to_date: any = 'MM/DD/YYYY';
	jc_from_date: any = '';
	offset: number = 0;
	constructor(private spinnerService: Ng4LoadingSpinnerService, private _ser: BaseapiService, private _r: Router, private _fb: FormBuilder, private _ar: ActivatedRoute, private changeDetectorRefs: ChangeDetectorRef, public snackBar: MatSnackBar) { }
	onJcFromDateChanged(event: IMyDateModel) {
		this.jc_from_date = event.formatted;
		console.log(this.jc_from_date);
	}
	onJcToDateChanged(event: IMyDateModel) {
		this.jc_to_date = event.formatted;
		console.log(this.jc_to_date);
	}
	res;
	notificationData: any = {};
	globalData: any = [];
	globalDataTable: any = [];
	ngOnInit() {
		// this.spinnerService.show()
		this.displayedColumns = this.columnNames.map(x => x.id);
		var today = new Date();
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = today.getDate();
		var mm = today.getMonth() + 1;
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var toDate = mm1 + '/' + dd1 + '/' + yyyy + ' 23:59:59';
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = dt.getDate();
		var mm = dt.getMonth() + 1;
		var yyyy = dt.getFullYear();

		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var fromDate = mm1 + '/' + dd1 + '/' + yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		this.generateTable(0, 50, fromDate, toDate);
	}
	pageEventHandle(event) {
		this.changeDetectorRefs.detectChanges();


		var today = new Date();
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = today.getDate();
		var mm = today.getMonth() + 1;
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var toDate = mm1 + '/' + dd1 + '/' + yyyy + ' 23:59:59';
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = dt.getDate();
		var mm = dt.getMonth() + 1;
		var yyyy = dt.getFullYear();

		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var fromDate = mm1 + '/' + dd1 + '/' + yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);

		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			var jcFromDate = fromDate;
			// var jcFromDate=latest_date+ " 00:00:00";
		} else {
			jcFromDate = this.jc_from_date + " 00:00:00";
		}

		var flag = 1;
		if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			var jcToDate = toDate;
			//var jcToDate =this.currentDate;

		} else {
			jcToDate = this.jc_to_date + " 23:59:59";
		}

		this.generateTable(event.pageIndex * 50, event.pageSize, jcFromDate, jcToDate);
	}

	generateTable(offset, size, fromDate, toDate) {

		this.length = 10 //data['total_results'];

		this.spinnerService.show();
		var request = {
			"app_name": localStorage.getItem('app_name'),
			// "app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"from_date": fromDate,
			"to_date": toDate,
			"offset": offset,
			"size": size
		};

		this._ser.getGenericFeedback(request).subscribe(
			res => {
				this.spinnerService.hide()
				//var jsonObj = JSON.parse(JSON.stringify(res));
				//this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['generic_feedback']);

				for (var i: any = 0; i < res['generic_feedback'].length; i++) {
					res['generic_feedback'][i].img_list = res['generic_feedback'][i].img_list.toString();
					/*	let jsonObj2 = {};
						//console.log(this.notificationData.generic_feedback[i]);
						res['generic_feedback'][i].notification_id=res['generic_feedback'][i].notification_id;
						res['generic_feedback'][i].created_by=res['generic_feedback'][i].notification_id;
						res['generic_feedback'][i].created_dt=res['generic_feedback'][i].created_dt;
						res['generic_feedback'][i].last_updated_by=res['generic_feedback'][i].notification_id;
						res['generic_feedback'][i].last_updated_at= res['generic_feedback'][i].updated_dt;
						res['generic_feedback'][i].notification_type=res['generic_feedback'][i].notification_type;
						res['generic_feedback'][i].notification_title=res['generic_feedback'][i].notification_title;
						res['generic_feedback'][i].notification_desc=res['generic_feedback'][i].notification_desc;
						res['generic_feedback'][i].notification_category=res['generic_feedback'][i].notification_category;
						res['generic_feedback'][i].notification_base_category=res['generic_feedback'][i].notification_base_category;
						res['generic_feedback'][i].notification_startDate=res['generic_feedback'][i].notification_startDate;
						res['generic_feedback'][i].notification_endDate=res['generic_feedback'][i].notification_endDate;
						res['generic_feedback'][i].associated_states=res['generic_feedback'][i].associated_states.toString();
						res['generic_feedback'][i].associated_cities=res['generic_feedback'][i].associated_cities.toString();
						res['generic_feedback'][i].associated_ppl=res['generic_feedback'][i].associated_ppl.toString();
						res['generic_feedback'][i].associated_pl=res['generic_feedback'][i].associated_pl.toString();
						res['generic_feedback'][i].kms_run=res['generic_feedback'][i].kms_run;
						//jsonObj2['Kms Run Operator']=res['generic_feedback'][i].kms_run_operator;
						if(res['generic_feedback'][i].is_chassis_flag){
						res['generic_feedback'][i].is_chassis_flag='Yes';
						}else{
						res['generic_feedback'][i].is_chassis_flag='No';
						}
						res['generic_feedback'][i].associated_chassis =res['generic_feedback'][i].associated_chassis.toString();;
						res['generic_feedback'][i].is_active=res['generic_feedback'][i].is_active;
						if(res['generic_feedback'][i].is_active){
						res['generic_feedback'][i].is_active='Yes';
						}else{
						res['generic_feedback'][i].is_active='No';
						}
					  // this.globalDataTable.push(jsonObj2);
					  */

				}
				//console.log(this.globalData);
				this.length = res['total_count'];
				this.dataSource = new MatTableDataSource(res['generic_feedback']);
				//console.log(' hii '+this.dataSource);
				this.dataSource.sort = this.sort;
				//this.globalDataTable=res['generic_feedback'];
			},
			error => {
				if (error.status == 401) {
					alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
			}
		);
	}
	downloadAsExcelFile() {
		this.spinnerService.show()
		this.offset = 0;
		this.globalData = []
		// this.globalData=[]
		var today = new Date();
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = today.getDate();
		var mm = today.getMonth() + 1;
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var toDate = mm1 + '/' + dd1 + '/' + yyyy + ' 23:59:59';
		var dt = new Date();
		dt.setDate(dt.getDate() - 28);
		var dd = dt.getDate();
		var mm = dt.getMonth() + 1;
		var yyyy = dt.getFullYear();

		if (dd < 10) {
			dd1 = '0' + dd.toString();
		} else {
			dd1 = dd;
		}
		if (mm < 10) {
			mm1 = '0' + mm.toString();
		} else {
			mm1 = mm;
		}
		var fromDate = mm1 + '/' + dd1 + '/' + yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);

		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			var jcFromDate = fromDate;
			// var jcFromDate=latest_date+ " 00:00:00";
		} else {
			jcFromDate = this.jc_from_date + " 00:00:00";
		}

		var flag = 1;
		if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			var jcToDate = toDate;
			//var jcToDate =this.currentDate;

		} else {
			jcToDate = this.jc_to_date + " 23:59:59";
		}
		var request = {
			"app_name": localStorage.getItem('app_name'),
			// "app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"offset": 0,
			"from_date": jcFromDate,
			"to_date": jcToDate,
			"size": 5
		};

		this._ser.getGenericFeedback(request).subscribe(
			res => {
				this.spinnerService.show();
				// this.globalData=[];
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData = JSON.parse(JSON.stringify(jsonObj));
				console.log(jsonObj);
				var num_list_cnt: number = this.notificationData.total_count;

				// if(num_list_cnt!= 0){
				for (var i: any = 0; i < this.notificationData.generic_feedback.length; i++) {
					let jsonObj2 = {};
					console.log(this.notificationData.generic_feedback[i]);
					jsonObj2['Id'] = this.notificationData.generic_feedback[i].id;
					jsonObj2['Created By'] = this.notificationData.generic_feedback[i].created_by;
					jsonObj2['Created At'] = this.notificationData.generic_feedback[i].created_dt;
					jsonObj2['Last Updated By'] = this.notificationData.generic_feedback[i].updated_by;
					jsonObj2['Last Updated At'] = this.notificationData.generic_feedback[i].updated_dt;
					jsonObj2['User Id'] = this.notificationData.generic_feedback[i].user;
					//jsonObj2['Feedback Type']=this.notificationData.generic_feedback[i].fb-area;
					jsonObj2['Image List'] = this.notificationData.generic_feedback[i].img_list.toString();
					jsonObj2['User Remarks'] = this.notificationData.generic_feedback[i].user_remarks;
					jsonObj2['Status'] = this.notificationData.generic_feedback[i].status;
					jsonObj2['Tml Remarks'] = this.notificationData.generic_feedback[i].tml_remarks;

					this.globalData.push(jsonObj2);

				}
				var num1: number = this.notificationData.total_count;
				var num2: number = this.notificationData.generic_feedback.length;
				var res1: number = 0
				res1 = Math.ceil(num1 / num2);
				console.log(res);
				for (var z = 1; z < res1; z++) {

					this.offset = this.offset + 5;
					//alert(this.offset)
					var request = {
						"app_name": localStorage.getItem('app_name'),
						// "app_name": "com.ttl.customersocialapp",
						"portal_app_name": "customersocialapp-portal",
						"app_version": "0.1",
						"device_id": "12345678",
						"offset": this.offset,
						"from_date": jcFromDate,
						"to_date": jcToDate,
						"size": 5
					};
					this._ser.getGenericFeedback(request).subscribe(
						res => {
							var jsonObj = JSON.parse(JSON.stringify(res));
							this.notificationData = JSON.parse(JSON.stringify(jsonObj));
							console.log("Inner")
							console.log(jsonObj);

							for (var i: any = 0; i < this.notificationData.generic_feedback.length; i++) {
								let jsonObj2 = {};
								jsonObj2['Id'] = this.notificationData.generic_feedback[i].id;
								jsonObj2['Created By'] = this.notificationData.generic_feedback[i].created_by;
								jsonObj2['Created At'] = this.notificationData.generic_feedback[i].created_dt;
								jsonObj2['Last Updated By'] = this.notificationData.generic_feedback[i].updated_by;
								jsonObj2['Last Updated At'] = this.notificationData.generic_feedback[i].updated_dt;
								jsonObj2['User Id'] = this.notificationData.generic_feedback[i].user;
								//jsonObj2['Feedback Type']=this.notificationData.generic_feedback[i].fb-area;
								jsonObj2['Image List'] = this.notificationData.generic_feedback[i].img_list.toString();
								jsonObj2['User Remarks'] = this.notificationData.generic_feedback[i].user_remarks;
								jsonObj2['Status'] = this.notificationData.generic_feedback[i].status;
								jsonObj2['Tml Remarks'] = this.notificationData.generic_feedback[i].tml_remarks;

								this.globalData.push(jsonObj2);

							}
						},
						error => {
							//this.setResult(error);
							if (error.status == 401) {
								alert(error.error.detail)
								// this._r.navigate(['/login']);
								this.setlogout()
							}
							if (error.status == 412) {
								alert(error.error.msg)
								this._r.navigate(['/login']);
							}
						}
					);


				}
				console.log(this.globalData);
				// 	}else{
				// 		let config = new MatSnackBarConfig();
				// config.verticalPosition = this.verticalPosition;
				// config.horizontalPosition = this.horizontalPosition;
				// config.duration = this.setAutoHide ? this.autoHide : 0;
				// this.message="No Data For download"
				// 		// alert("No Data For download")
				// 		this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

				// 		return
				// 	}
			},
			error => {
				let config = new MatSnackBarConfig();
				config.verticalPosition = this.verticalPosition;
				config.horizontalPosition = this.horizontalPosition;
				config.duration = this.setAutoHide ? this.autoHide : 0;
				//this.setResult(error);
				if (error.status == 401) {
					this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

					// alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				}
				if (error.status == 412) {
					this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

					// alert(error.error.msg)
					//this._r.navigate(['/login']);
				}
			}
		);


		//this.psfdata=JSON.parse(JSON.stringify(msg1));
		setTimeout(() => {
			// alert()
			this.spinnerService.show();
			if (this.globalData != '') {
				var ws = XLSX.utils.json_to_sheet(this.globalData);
				var wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Generic Feedback Data");
				XLSX.writeFile(wb, "Generic Feedback Worksheet.xlsx");
			} else {
				let config = new MatSnackBarConfig();
				config.verticalPosition = this.verticalPosition;
				config.horizontalPosition = this.horizontalPosition;
				config.duration = this.setAutoHide ? this.autoHide : 0;
				this.snackBar.open('No Data For Download', this.action ? this.actionButtonLabel : undefined, config);

			}
			// this.spinnerService.hide();
		}, 1000);
		this.spinnerService.hide();
	}

	search() {
		//alert('hii');

		let config = new MatSnackBarConfig();
		config.verticalPosition = this.verticalPosition;
		config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
		var flag = 1;
		//alert('hii');
		if ((this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") && (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "")) {
			flag = 0;
			this.spinnerService.hide();
			// alert("Select Date")
			this.message = "Please Select From Date and To Date"
			// alert("No Data For download")
			this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

			return

		} else {
			if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
				var jcFromDate = '';
				flag = 0;
				//	alert("Select From Date");
				this.message = "Please Select From Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
				// var jcFromDate=latest_date+ " 00:00:00";
			} else {
				jcFromDate = this.jc_from_date + " 00:00:00";
			}
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
				var jcToDate = '';
				//alert("Select To Date");
				this.message = "Please Select To Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

				//	$("#msgModal").modal('show');
				flag = 0;
				//var jcToDate =this.currentDate;

			} else {
				jcToDate = this.jc_to_date + " 23:59:59";
			}

			var isLarger = new Date(jcToDate) < new Date(jcFromDate);
			//alert(isLarger)
			if (new Date(jcToDate) < new Date(jcFromDate)) {
				flag = 0;
				//alert("To date must less than Start Date")
				this.message = "To date must be greater than From Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

				//alert(flag)
				// this.spinnerService.hide();
			}
			// var flag=1;
			// if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			//   var jcToDate = '';
			//  //var jcToDate =this.currentDate;

			// } else {
			//   jcToDate = this.jc_to_date + " 23:59:59";
			// }

			// var isLarger = new Date(jcToDate) < new Date(jcFromDate);
			//alert(isLarger)
			// if(new Date(jcToDate) < new Date(jcFromDate)){
			// 	flag=0;
			//   //alert(flag)
			//   // this.spinnerService.hide();
			// }
			var timeDiff = Math.abs(new Date(jcFromDate).getTime() - new Date(jcToDate).getTime());
			var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

		}
		//alert(jcToDate +' ' + jcFromDate);
		if (flag == 1) {
			this.generateTable(0, 50, jcFromDate, jcToDate);
		}
		/*this.spinnerService.show();
		var request = {
			"app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"offset":0,
			"size":5
		};
			
		this._ser.getGenericFeedback(request).subscribe(
			res => {
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['generic_feedback']);
				
				for(var i:any=0;i<res['generic_feedback'].length;i++){
					let jsonObj2 = {};
					//console.log(this.notificationData.generic_feedback[i]);
					res['generic_feedback'][i].notification_id=res['generic_feedback'][i].notification_id;
					res['generic_feedback'][i].created_by=res['generic_feedback'][i].notification_id;
					res['generic_feedback'][i].created_dt=res['generic_feedback'][i].created_dt;
					res['generic_feedback'][i].last_updated_by=res['generic_feedback'][i].notification_id;
					res['generic_feedback'][i].last_updated_at= res['generic_feedback'][i].updated_dt;
					res['generic_feedback'][i].notification_type=res['generic_feedback'][i].notification_type;
					res['generic_feedback'][i].notification_title=res['generic_feedback'][i].notification_title;
					res['generic_feedback'][i].notification_desc=res['generic_feedback'][i].notification_desc;
					res['generic_feedback'][i].notification_category=res['generic_feedback'][i].notification_category;
					res['generic_feedback'][i].notification_base_category=res['generic_feedback'][i].notification_base_category;
					res['generic_feedback'][i].notification_startDate=res['generic_feedback'][i].notification_startDate;
					res['generic_feedback'][i].notification_endDate=res['generic_feedback'][i].notification_endDate;
					res['generic_feedback'][i].associated_states=res['generic_feedback'][i].associated_states.toString();
					res['generic_feedback'][i].associated_cities=res['generic_feedback'][i].associated_cities.toString();
					res['generic_feedback'][i].associated_ppl=res['generic_feedback'][i].associated_ppl.toString();
					res['generic_feedback'][i].associated_pl=res['generic_feedback'][i].associated_pl.toString();
					res['generic_feedback'][i].kms_run=res['generic_feedback'][i].kms_run;
					//jsonObj2['Kms Run Operator']=res['generic_feedback'][i].kms_run_operator;
					if(res['generic_feedback'][i].is_chassis_flag){
					res['generic_feedback'][i].is_chassis_flag='Yes';
					}else{
					res['generic_feedback'][i].is_chassis_flag='No';
					}
					res['generic_feedback'][i].associated_chassis =res['generic_feedback'][i].associated_chassis.toString();;
					res['generic_feedback'][i].is_active=res['generic_feedback'][i].is_active;
					if(res['generic_feedback'][i].is_active){
					res['generic_feedback'][i].is_active='Yes';
					}else{
					res['generic_feedback'][i].is_active='No';
					}
				  // this.globalDataTable.push(jsonObj2);
					
				}
				//console.log(this.globalData);
				this.length = res['total_count'];
					this.dataSource = new MatTableDataSource(res['generic_feedback']);
					//console.log(' hii '+this.dataSource);
					this.dataSource.sort = this.sort;
			//this.globalDataTable=res['generic_feedback'];
			},
			error => {
				this.setResult(error);
			}
		);*/


	}
	setResult(res) {
		this.res = res;
		console.log(JSON.stringify(res));
	}
	setlogout() {
		localStorage.setItem('access_token', '');
		localStorage.setItem('device_id', '');
		this._r.navigate(['/login']);
		//return
	}
}
