import { Component, OnInit,ViewChild,ChangeDetectorRef } from '@angular/core';
import { NgModule } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatPaginator, MatTableDataSource, MatInput,MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material';
import {
  MatInputModule, MatPaginatorModule, MatProgressSpinnerModule,
  MatSortModule, MatTableModule, MatSort,MatDialogRef,MatDialog
} from "@angular/material";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { PageEvent } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import {FormsModule,} from '@angular/forms';
import { FormBuilder , FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { SimpleDialogComponent } from 'src/app/simple-dialog/simple-dialog.component'
// import {DialogModule} from 'primeng/dialog';

@Component({
  selector: 'app-post-service-feedback',
  templateUrl: './post-service-feedback.component.html',
  styleUrls: ['./post-service-feedback.component.css']
})
export class PostServiceFeedbackComponent implements OnInit {
	simpleDialog: MatDialogRef<SimpleDialogComponent>;
	length = 0;
	pageSize = 50;
	// pageSizeOptions: number[] = [5, 10, 25, 100];
	pageSizeOptions: number[] = [50];

	// MatPaginator Output
	pageEvent: PageEvent;
	dataSource;
	displayedColumns = [];
	psfdata:any='';
	message: string = 'Snack Bar opened.';
  //actionButtonLabel: string = 'Retry';
  actionButtonLabel: string = '';
  action: boolean = true;
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  addExtraClass: boolean = false;
  	@ViewChild(MatSort, {static: true}) sort: MatSort;

	@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

	
	 columnNames = [
		{
			id:"user",
			value:"User"
		},
		{
			id:"chassis_number",
			value: "Chassis Num"
		},
		{
			id:"registration_number",
			value:"Registration Num"
		},
		{
			id:"sr_number",
			value: "SR Num"
		},
		{
			id:"sr_type",
			value: "SR Type"
		},
		{
			id:"job_number",
			value: "JC No"
		},
		{
			id:"jc_close_date",
			value:"JC Closed Date"
		},
		{
			id:"general_feedback",
			value:"General Feedback"
		},
		{
			id:"psf_feedback_rating",
			value:"Ratings"
		},
		{
			id:"siebel_status",
			value:"Siebel Status"
		},
		{
			id:"failure_reason",
			value:"Faliure Reason"
		},
		{
			id:"created_dt",
			value:"Created At"
		},
		{
			id:"created_by",
			value:"Created By"
		},
		{
			id:"updated_dt",
			value: "Last Updated At"
		},
				{
			id:"updated_by",
			value: "Last Updated By"
		}	
		];
		public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),

  }
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    
    
    var day = today.getDate();
    if (day != 1) {
      day = day - 1;
    }
    
    var month = today.getMonth() + 1; //January is 0!
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    markCurrentDay: true,
  }; 
   jc_to_date:any='';
  feedback_from_date:any='yyyy-mm-dd';
  feedback_to_date:any='yyyy-mm-dd';
  jc_from_date:any='';
   offset: number = 0;
  constructor(private spinnerService: Ng4LoadingSpinnerService,private _ser:BaseapiService,private _r:Router,private _fb:FormBuilder, private _ar: ActivatedRoute,private changeDetectorRefs: ChangeDetectorRef,private dialogModel: MatDialog,public snackBar: MatSnackBar) { }
  onJcFromDateChanged(event: IMyDateModel) {
		this.jc_from_date = event.formatted;
		console.log(this.jc_from_date);
	  }
	  onJcToDateChanged(event: IMyDateModel) {
		this.jc_to_date = event.formatted;
		console.log(this.jc_to_date);
	  }
 res;
	  notificationData:any={};
	  globalData:any=[];
	  globalDataTable:any=[];
	ngOnInit() {
		//this.dialog()
	    this.displayedColumns = this.columnNames.map(x => x.id);
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		this.generateTable(0,50,fromDate,toDate);
	}
		pageEventHandle(event) {
      this.changeDetectorRefs.detectChanges();
		
		
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		
		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = fromDate;
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			  var jcToDate = toDate;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
		
		this.generateTable(event.pageIndex * 50, event.pageSize,jcFromDate,jcToDate);
	}
  dialog() {
    // alert()
    this.simpleDialog = this.dialogModel.open(SimpleDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Save',
          cancel: 'OK'
        }
      }
    });
		}
		dialog_dynamic(data) {
			//alert(data)
			this.simpleDialog = this.dialogModel.open(SimpleDialogComponent,{
				data:{
					message: data,
					buttonText: {
						ok: 'Save',
						cancel: 'Close'
					}
				}
			});
			}
			setlogout(){
				localStorage.setItem('access_token','');
				localStorage.setItem('device_id','');
				this._r.navigate(['/login']);
				return
			}
      generateTable(offset,size,fromDate,toDate){
		//this.length =  10 //data['total_results'];
		
		this.spinnerService.show();
		var request = {
			"app_name": localStorage.getItem('app_name'),
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": localStorage.getItem('device_id'),
			"from_date":fromDate, 
			"to_date":toDate, 
			"offset":offset,
			"size":size
		};
			
		this._ser.getPsf(request).subscribe(
			res => {
				//var jsonObj = JSON.parse(JSON.stringify(res));
				//this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['psf_list']);
				
				for(var i:any=0;i<res['psf_list'].length;i++){
					//res['psf_list'][i].psf_feedback_rating= JSON.parse(JSON.stringify(res['psf_list'][i].psf_feedback_rating[0].question)); //res['psf_list'][i].psf_feedback_rating.toString();
					var htmlstring = '<table class="table"><thead> <tr><th>Question No</th><th>Firstname</th> <th>Lastname</th></tr></thead><tbody>';
					for(var j:any =0; j< res['psf_list'][i].psf_feedback_rating.length;j++){
						//alert(res['psf_list'][i].psf_feedback_rating[0].question);
						var count = j + 1; 
							htmlstring +=  '<tr><th>'+ count + '</th> <th>'+ res['psf_list'][i].psf_feedback_rating[j].question+ '</th> <th>'+res['psf_list'][i].psf_feedback_rating[j].rating+'</th></tr>';
					}
					htmlstring += '</tbody></table>';
				//	res['psf_list'][i].psf_feedback_rating=htmlstring;
				//alert(res['psf_list'][i].psf_feedback_rating);
				/*	let jsonObj2 = {};
					//console.log(this.notificationData.psf_list[i]);
					res['psf_list'][i].notification_id=res['psf_list'][i].notification_id;
					res['psf_list'][i].created_by=res['psf_list'][i].notification_id;
					res['psf_list'][i].created_dt=res['psf_list'][i].created_dt;
					res['psf_list'][i].last_updated_by=res['psf_list'][i].notification_id;
					res['psf_list'][i].last_updated_at= res['psf_list'][i].updated_dt;
					res['psf_list'][i].notification_type=res['psf_list'][i].notification_type;
					res['psf_list'][i].notification_title=res['psf_list'][i].notification_title;
					res['psf_list'][i].notification_desc=res['psf_list'][i].notification_desc;
					res['psf_list'][i].notification_category=res['psf_list'][i].notification_category;
					res['psf_list'][i].notification_base_category=res['psf_list'][i].notification_base_category;
					res['psf_list'][i].notification_startDate=res['psf_list'][i].notification_startDate;
					res['psf_list'][i].notification_endDate=res['psf_list'][i].notification_endDate;
					res['psf_list'][i].associated_states=res['psf_list'][i].associated_states.toString();
					res['psf_list'][i].associated_cities=res['psf_list'][i].associated_cities.toString();
					res['psf_list'][i].associated_ppl=res['psf_list'][i].associated_ppl.toString();
					res['psf_list'][i].associated_pl=res['psf_list'][i].associated_pl.toString();
					res['psf_list'][i].kms_run=res['psf_list'][i].kms_run;
					//jsonObj2['Kms Run Operator']=res['psf_list'][i].kms_run_operator;
					if(res['psf_list'][i].is_chassis_flag){
					res['psf_list'][i].is_chassis_flag='Yes';
					}else{
					res['psf_list'][i].is_chassis_flag='No';
					}
					res['psf_list'][i].associated_chassis =res['psf_list'][i].associated_chassis.toString();;
					res['psf_list'][i].is_active=res['psf_list'][i].is_active;
					if(res['psf_list'][i].is_active){
					res['psf_list'][i].is_active='Yes';
					}else{
					res['psf_list'][i].is_active='No';
					}
				  // this.globalDataTable.push(jsonObj2);
				  */
					
				}
				//console.log(this.globalData);
				this.length = res['total_count'];
					this.dataSource = new MatTableDataSource(res['psf_list']);
					//console.log(' hii '+this.dataSource);
					this.dataSource.sort = this.sort;
			//this.globalDataTable=res['psf_list'];
			},
			error => {
				let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
				if (error.status == 401) {
					this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

					//alert(error.error.detail)
					// this._r.navigate(['/login']);
					this.setlogout()
				};
			}
		);
	}
	
	downloadAsExcelFile()
	{
		this.offset= 0;
		this.spinnerService.show();
		var today = new Date();
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = today.getDate();
		var mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		var dd1;
		var mm1;
		if(dd<10) 
		{
			dd1 ='0' + dd.toString() ; 
		}else{
			dd1 = dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		}else{
			mm1 = mm;
		}
		var toDate = mm1+'/'+dd1+'/'+yyyy + ' 23:59:59';
		var dt = new Date();
        dt.setDate( dt.getDate() - 28 );
		var dd = dt.getDate();
		var mm = dt.getMonth()+1; 
		var yyyy = dt.getFullYear();
		
		if(dd<10) 
		{
			dd1='0' + dd.toString() ; 
		} else{
			dd1 =dd;
		}
		if(mm<10) 
		{
			mm1='0' + mm.toString() ; 
		} else{
			mm1= mm;
		}
		var fromDate =  mm1+'/'+dd1+'/'+yyyy + ' 00:00:00';
		//alert(fromDate + ' ' + toDate);
		
		if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = fromDate;
		  // var jcFromDate=latest_date+ " 00:00:00";
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
			  var jcToDate = toDate;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
		var request = {
			"app_name": localStorage.getItem('app_name'),
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": localStorage.getItem('device_id'),
			"offset": 0,
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"size":5
		};
			
		this._ser.getPsf(request).subscribe(
			res => {
				this.globalData=[];
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(jsonObj);
				var num_list_cnt: number = this.notificationData.total_count;
				// if(num_list_cnt!= 0){
				for(var i:any=0;i<this.notificationData.psf_list.length;i++){
					let jsonObj2 = {};
					console.log(this.notificationData.psf_list[i]);
					jsonObj2['User']=this.notificationData.psf_list[i].user;
					jsonObj2['Created By']=this.notificationData.psf_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.psf_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.psf_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.psf_list[i].updated_dt;
					jsonObj2['Chassis No']=this.notificationData.psf_list[i].chassis_number;
					//jsonObj2['Feedback Type']=this.notificationData.psf_list[i].fb-area;
					jsonObj2['JC No']=this.notificationData.psf_list[i].job_number;
					jsonObj2['Sr No.']=this.notificationData.psf_list[i].sr_number;
					for(var j = 0; j<this.notificationData.psf_list[i].psf_feedback_rating.length;j++)
					{
						console.log(this.notificationData.psf_list[i].psf_feedback_rating[j].question);
						jsonObj2[this.notificationData.psf_list[i].psf_feedback_rating[j].question]=this.notificationData.psf_list[i].psf_feedback_rating[j].rating;
					}
					jsonObj2['General Feedback']=this.notificationData.psf_list[i].general_feedback;
					jsonObj2['Sebeil Status']=this.notificationData.psf_list[i].siebel_status;
					jsonObj2['Reason']=this.notificationData.psf_list[i].failure_reason;
					jsonObj2['Registration Number']=this.notificationData.psf_list[i].registration_number;
					jsonObj2['JC Close Date']=this.notificationData.psf_list[i].jc_close_date;
					

					
					
				   this.globalData.push(jsonObj2);
					
				}
				var num1: number = this.notificationData.total_count;
				  var num2: number = this.notificationData.psf_list.length;
				  var res1: number = 0
				  res1 = Math.ceil(num1 / num2);
					console.log(res);
					
				  for (var z = 1; z < res1; z++) {
					  
					this.offset = this.offset + 5;
					//alert(this.offset)
					var request = {
					"app_name": localStorage.getItem('app_name'),
					"portal_app_name": "customersocialapp-portal",
					"app_version": "0.1",
					"device_id": localStorage.getItem('device_id'),
					"offset": this.offset,
					"from_date":jcFromDate, 
					"to_date":jcToDate, 
					"size":5
					};
					this._ser.getPsf(request).subscribe(
			res => {
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(jsonObj);
				
				for(var i:any=0;i<this.notificationData.psf_list.length;i++){
					let jsonObj2 = {};
					jsonObj2['User']=this.notificationData.psf_list[i].user;
					jsonObj2['Created By']=this.notificationData.psf_list[i].created_by;
					jsonObj2['Created At']=this.notificationData.psf_list[i].created_dt;
					jsonObj2['Last Updated By']=this.notificationData.psf_list[i].updated_by;
					jsonObj2['Last Updated At']=this.notificationData.psf_list[i].updated_dt;
					jsonObj2['Chassis No']=this.notificationData.psf_list[i].chassis_number;
					//jsonObj2['Feedback Type']=this.notificationData.psf_list[i].fb-area;
					jsonObj2['JC No']=this.notificationData.psf_list[i].job_number;
					jsonObj2['Sr No.']=this.notificationData.psf_list[i].sr_number;
					for(var j = 0; j<this.notificationData.psf_list[i].psf_feedback_rating.length;j++)
					{
						console.log(this.notificationData.psf_list[i].psf_feedback_rating[j].question);
						jsonObj2[this.notificationData.psf_list[i].psf_feedback_rating[j].question]=this.notificationData.psf_list[i].psf_feedback_rating[j].rating;
					}
					jsonObj2['General Feedback']=this.notificationData.psf_list[i].general_feedback;
					jsonObj2['Sebeil Status']=this.notificationData.psf_list[i].siebel_status;
					jsonObj2['Reason']=this.notificationData.psf_list[i].failure_reason;
					jsonObj2['Registration Number']=this.notificationData.psf_list[i].registration_number;
					jsonObj2['JC Close Date']=this.notificationData.psf_list[i].jc_close_date;
					
					
					
				   this.globalData.push(jsonObj2);
					
				}
								},
			error => {
				this.setResult(error);
			}
		);
		
					
				  }
				console.log(this.globalData);
		// 	}else{
		// 		let config = new MatSnackBarConfig();
    // config.verticalPosition = this.verticalPosition;
    // config.horizontalPosition = this.horizontalPosition;
		// config.duration = this.setAutoHide ? this.autoHide : 0;
		// this.message="No Data For download"
		// 		// alert("No Data For download")
		// 		this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

		// 		return
		// 	}
		},
			error => {
				this.setResult(error);
			}
		);
		
		 
		//this.psfdata=JSON.parse(JSON.stringify(msg1));
		if(this.globalData!=''){
		setTimeout(() => {
			this.spinnerService.show();
			if(this.globalData!=''){
            var ws = XLSX.utils.json_to_sheet(this.globalData);
			var wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Post Service Feedback Data");
			XLSX.writeFile(wb, "Post Service Feedback Worksheet.xlsx");
		}else{
			let config = new MatSnackBarConfig();
			config.verticalPosition = this.verticalPosition;
			config.horizontalPosition = this.horizontalPosition;
			config.duration = this.setAutoHide ? this.autoHide : 0;
			this.snackBar.open('No Data For Download', this.action ? this.actionButtonLabel : undefined, config);

		}
			this.spinnerService.hide();
			this.globalData = [];
		}, 1000);
	}
	}
	
	search(){
		//alert('hii');
		let config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
		config.duration = this.setAutoHide ? this.autoHide : 0;
		var flag=1;
		if((this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "")&& (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == ""))
		{
			this.spinnerService.hide();
		//	alert("Select Date")
		this.message="Please Select From Date and To Date"
		// alert("No Data For download")
		this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

		return
		}else{
			if (this.jc_from_date === null || this.jc_from_date === undefined || this.jc_from_date == "") {
			  var jcFromDate = '';
			// var jcFromDate=latest_date+ " 00:00:00";
			flag=0;
			//	alert("Select From Date");
			this.message="Please Select From Date"
			this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
		  
			} else {
			  jcFromDate = this.jc_from_date + " 00:00:00";
			}

			var flag=1;
			if (this.jc_to_date === null || this.jc_to_date === undefined || this.jc_to_date == "") {
				var jcToDate = '';
				this.message="Please Select To Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

			//	$("#msgModal").modal('show');
				flag=0;
			 //var jcToDate =this.currentDate;
			
			} else {
			  jcToDate = this.jc_to_date + " 23:59:59";
			}
			
			var isLarger = new Date(jcToDate) < new Date(jcFromDate);
			//alert(isLarger)
			if(new Date(jcToDate) < new Date(jcFromDate)){
				flag=0;
			
				//alert("To date must less than Start Date")
				this.message="To date must be greater than From Date"
				this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

			  //alert(flag)
			  // this.spinnerService.hide();
			}
			var timeDiff = Math.abs(new Date(jcFromDate).getTime() - new Date(jcToDate).getTime());
			var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
			
		}
		//alert(jcToDate +' ' + jcFromDate);
		if(flag==1){
		this.generateTable(0,50,jcFromDate,jcToDate);
		}
		/*this.spinnerService.show();
		var request = {
			"app_name": "com.ttl.customersocialapp",
			"portal_app_name": "customersocialapp-portal",
			"app_version": "0.1",
			"device_id": "12345678",
			"from_date":jcFromDate, 
			"to_date":jcToDate, 
			"offset":0,
			"size":5
		};
			
		this._ser.getPsf(request).subscribe(
			res => {
				var jsonObj = JSON.parse(JSON.stringify(res));
				this.notificationData=JSON.parse(JSON.stringify(jsonObj));
				console.log(res['psf_list']);
				
				for(var i:any=0;i<res['psf_list'].length;i++){
					let jsonObj2 = {};
					//console.log(this.notificationData.psf_list[i]);
					res['psf_list'][i].notification_id=res['psf_list'][i].notification_id;
					res['psf_list'][i].created_by=res['psf_list'][i].notification_id;
					res['psf_list'][i].created_dt=res['psf_list'][i].created_dt;
					res['psf_list'][i].last_updated_by=res['psf_list'][i].notification_id;
					res['psf_list'][i].last_updated_at= res['psf_list'][i].updated_dt;
					res['psf_list'][i].notification_type=res['psf_list'][i].notification_type;
					res['psf_list'][i].notification_title=res['psf_list'][i].notification_title;
					res['psf_list'][i].notification_desc=res['psf_list'][i].notification_desc;
					res['psf_list'][i].notification_category=res['psf_list'][i].notification_category;
					res['psf_list'][i].notification_base_category=res['psf_list'][i].notification_base_category;
					res['psf_list'][i].notification_startDate=res['psf_list'][i].notification_startDate;
					res['psf_list'][i].notification_endDate=res['psf_list'][i].notification_endDate;
					res['psf_list'][i].associated_states=res['psf_list'][i].associated_states.toString();
					res['psf_list'][i].associated_cities=res['psf_list'][i].associated_cities.toString();
					res['psf_list'][i].associated_ppl=res['psf_list'][i].associated_ppl.toString();
					res['psf_list'][i].associated_pl=res['psf_list'][i].associated_pl.toString();
					res['psf_list'][i].kms_run=res['psf_list'][i].kms_run;
					//jsonObj2['Kms Run Operator']=res['psf_list'][i].kms_run_operator;
					if(res['psf_list'][i].is_chassis_flag){
					res['psf_list'][i].is_chassis_flag='Yes';
					}else{
					res['psf_list'][i].is_chassis_flag='No';
					}
					res['psf_list'][i].associated_chassis =res['psf_list'][i].associated_chassis.toString();;
					res['psf_list'][i].is_active=res['psf_list'][i].is_active;
					if(res['psf_list'][i].is_active){
					res['psf_list'][i].is_active='Yes';
					}else{
					res['psf_list'][i].is_active='No';
					}
				  // this.globalDataTable.push(jsonObj2);
					
				}
				//console.log(this.globalData);
				this.length = res['total_count'];
					this.dataSource = new MatTableDataSource(res['psf_list']);
					//console.log(' hii '+this.dataSource);
					this.dataSource.sort = this.sort;
			//this.globalDataTable=res['psf_list'];
			},
			error => {
				this.setResult(error);
			}
		);*/
		
		
	}
	setResult(res) {
		this.res = res;
		console.log(JSON.stringify(res));
	}
	ShowPsfRating(data){
			//alert(data);
			var jsonObj = (JSON.stringify(data));
			alert(data);
	}

}
