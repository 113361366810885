import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatInput, MatButtonModule } from '@angular/material';
//import { MatFileUploadModule, } from 'angular-material-fileupload';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as $ from "jquery";
import { DatePipe, formatDate } from '@angular/common'
import { FileUploader } from "ng2-file-upload";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material';

@Component({
    selector: 'app-add-notification',
    templateUrl: './add-notification.component.html',
    styleUrls: ['./add-notification.component.css']
})

export class AddNotificationComponent implements OnInit {
    @ViewChild('form', {static: true}) form: { nativeElement: { reset: () => void; }; }
    notifyForm: FormGroup;
    jc_to_date: any = 'yyyy-mm-dd';
    feedback_from_date: any = 'yyyy-mm-dd';
    feedback_to_date: any = 'yyyy-mm-dd';
    jc_from_date: any = 'yyyy-mm-dd';

    res;
    message: string = 'Snack Bar opened.';
    //actionButtonLabel: string = 'Retry';
    actionButtonLabel: string = '';
    action: boolean = true;
    setAutoHide: boolean = true;
    autoHide: number = 2000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    addExtraClass: boolean = false;
    public uploader: FileUploader



    constructor(private spinnerService: Ng4LoadingSpinnerService, private _ser: BaseapiService, private _r: Router, el: ElementRef, private _fb: FormBuilder, private _ar: ActivatedRoute, public datepipe: DatePipe, public snackBar: MatSnackBar) {
        this.notifyForm = this._fb.group({
            // FullName:['',[Validators.required,Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
            // Email:['',[Validators.required,Validators.pattern("[a-zA-Z0-9]{3,}[@]{1}[a-zA-Z0-9]{3,}[.]{1}[a-zA-Z0-9]{2,}")]],
            // MobileNo:['',[Validators.required,Validators.pattern("[0-9]{10}")]],
            // State:['',[Validators.required]],
            // notification_category:['',[]], // Comment by Rahul
            // notification_title:['',[Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
            // notification_title:['',[Validators.pattern("'^[a-zA-Z \-\']$'")]],
            notification_title: ['', []],
            notification_type: ['', []],

            //notification_category:['',[Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
            notification_desc: ['', []],
            notification_startDate: ['', []],
            notification_endDate: ['', []],
            kms_run_operator: ['>=', []],
            // kms_run:['', [Validators.pattern("[0-9]")]],
            kms_run: ['', []],
            state: ['', []],
            city: ['', []],
            ppl: ['', []],
            pl: ['', []],
            banner_images: ['', []],
            ready_to_publish: ['false', []],
            is_active: ['false', []],
            banner: ['']
        })
    }
    isset = true;
    arrStates;
    arrPPL;
    arrPL;
    stEerrormsg;
    arrNotificationType;
    arrCities
    files: FileList;
    arr
    ar
    ban_img


    onJcFromDateChanged(event: IMyDateModel) {
        if (event.formatted != '') {
            this.jc_from_date = event.formatted;
        } else {
            this.jc_from_date = '';
        }
    }
    onJcToDateChanged(event: IMyDateModel) {
        if (event.formatted != '') {
            this.jc_to_date = event.formatted;
        } else {
            this.jc_to_date = '';
        }
    }

    flag = false;
    ngOnInit() {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        this._ser.changeUrl('true');
        $('.viewnotify').click(function () {
            $('ul.nav li a').removeClass("active1")
            // $(this).css("background-color: #213a89")

            $('.viewnotification').addClass("active");
        });
        $('.selectclass').select2()
        $('.notificationType').select2({
            tags: true
        })
        /***************STATE List API**************/
        this._ser.getStateList().subscribe(res => {
            this.arrStates = res;

            var statearr = [];
            for (var i = 0; i < this.arrStates.length; i++) {
                statearr.push(this.arrStates[i].name)
            } 
            var data1 = {
                "device_id": localStorage.getItem('device_id'),
                "app_version": "0.1",
                "app_name": localStorage.getItem('app_name'),
                "state_list": statearr
            }

            this._ser.getCities(data1).subscribe(res => {
                this.arrCities = res;
            },
            error => {
                //this.arrCities = ['all'];
                if (error.status == 401) {
                    //alert(error.error.detail)
                    this._r.navigate(['/login']);
                    return
                }
                if (error.status == 412) {
                    //alert(error.error.msg)
                    return
                }
            })

            return this.arrStates
        },
        error => {
            if (error.status == 401) {
                this.stEerrormsg = error.error.detail
                this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                // alert(error.error.detail)
                this._r.navigate(['/login']);
                return
            }
            if (error.status == 412) {
                this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                // alert(error.error.msg)
                return
            }
        })
        /***************PPL List API**************/
        this._ser.getPPL().subscribe(res => {
            this.arrPPL = res;

            var plarray = [];
            for (var i = 0; i < this.arrPPL.length; i++) {
                plarray.push({ 'ppl': this.arrPPL[i].ppl, 'ppl_id': this.arrPPL[i].ppl_id })
            }

            var data1 = {
                "device_id": localStorage.getItem('device_id'),
                "app_version": "0.1",
                "app_name": localStorage.getItem('app_name'),
                "ppl_list": plarray
            }
            this._ser.getPL(data1).subscribe(res => {
                this.arrPL = res;
            },
            error => {
                if (error.status == 401) {  
                    //alert(error.error.detail)
                    this._r.navigate(['/login']);
                    return
                }
                if (error.status == 412) {
                    //alert(error.error.msg)
                    return
                }
            })

            return this.arrPPL
        },
        error => {
            if (error.status == 401) {
                this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                // alert(error.error.detail)
                this._r.navigate(['/login']);
                return
            }
            if (error.status == 412) {
                this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
                // alert(error.error.msg)
                return
            }
        })
        // alert(JSON.stringify(this.arrPPL))
        /*************Notification Type*/
        this._ser.getNotificationType().subscribe(res => {
            this.arrNotificationType = res;
            return this.arrNotificationType
        },
        error => {
            if (error.status == 401) {
                this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                // alert(error.error.detail)
                this._r.navigate(['/login']);
                return
            }
            if (error.status == 412) {
                this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
                // alert(error.error.msg)
                return
            }
        })

        if (this._ar.snapshot.params.id != undefined) {
            //alert(this.arrPPL)
            this.flag = true;

            const id = this._ar.snapshot.params.id;

            var data = {
                "app_name": localStorage.getItem('app_name'),
                "portal_app_name": "customersocialapp-portal",
                "app_version": "0.1",
                "device_id": localStorage.getItem('device_id'),
                "id": id
            }



            this._ser.userData(data).subscribe(res => {
                var notification_endDate = this.datepipe.transform(res['notification']['notification_endDate'], 'MM/dd/yyyy');
                this.notifyForm.patchValue({
                    notification_endDate: {
                        date: notification_endDate
                    }
                });
                var start_date = this.datepipe.transform(res['notification']['notification_startDate'], 'MM/dd/yyyy');
                this.notifyForm.patchValue({ notification_startDate: { date: start_date } });
                this.jc_from_date = start_date;
                this.jc_to_date = notification_endDate;
                this.notifyForm.controls['notification_type'].patchValue(res['notification']['notification_type']);
                // this.notifyForm.controls['notification_category'].patchValue(res['notification']['notification_category']); // Comment by Rahul
                this.notifyForm.controls['notification_title'].patchValue(res['notification']['notification_title']);
                this.notifyForm.controls['notification_desc'].patchValue(res['notification']['notification_desc']);
                this.notifyForm.controls['kms_run'].patchValue(res['notification']['kms_run']);
                this.notifyForm.controls['kms_run_operator'].patchValue(res['notification']['kms_run_operator']);
                this.notifyForm.controls['state'].patchValue(res['notification']['associated_states']);
                this.notifyForm.controls['city'].patchValue(res['notification']['associated_cities']);
                this.notifyForm.controls['ppl'].patchValue(res['notification']['associated_ppl']);
                this.notifyForm.controls['pl'].patchValue(res['notification']['associated_pl']);
                // this.notifyForm.controls['ready_to_publish'].patchValue(res['notification']['is_active']);  
                if (res['notification']['ready_to_publish'] == true) {
                    this.notifyForm.controls['ready_to_publish'].patchValue('true');
                } else {
                    this.notifyForm.controls['ready_to_publish'].patchValue('false');
                }

                if (res['notification']['is_active'] == true) {
                    this.notifyForm.controls['is_active'].patchValue('true');
                } else {
                    this.notifyForm.controls['is_active'].patchValue('false');
                }

                this.ban_img = res['notification']['banner_images']
                //  this.ban_img="https://s3-eu-west-1.amazonaws.com/skin-bucket/tmscpv/dev/notification/images/notification_104_09_32_18_06_2019_0.png"
                //this.notifyForm.controls['banner'].patchValue("https://s3-eu-west-1.amazonaws.com/skin-bucket/tmscpv/dev/notification/images/notification_104_09_32_18_06_2019_0.png");
                // this.notifyForm.controls['citylist'].patchValue(res['notification']['associated_cities']);
                //return this.arrCities
                /****************************************Load Cities*********************** */
                this.edit_loadNotificationType();
                this.edit_loadCities(res['notification']['associated_states']);
                this.edit_loadPl(res['notification']['associated_ppl']);
                // alert(this.arrPPL)addNotification
                /*************************Load PPL */
                //var d=this.edit_loadPpl(res['notification']['associated_ppl']);

                //this.notifyForm.controls['ppl'].patchValue(d);
                // var data1 = {
                //   "device_id": localStorage.getItem('device_id'),
                //   "app_version": "0.1",
                //   "app_name": localStorage.getItem('app_name'),
                //   "state_list": res['notification']['associated_states']
                // }
                // this._ser.getCities(data1).subscribe(res => {
                //   this.arrCities = res;
                //   return this.arrCities
                // },
                //   error => {
                //     if (error.status == 401) {

                //       alert(error.error.detail)
                //       this._r.navigate(['/login']);
                //     }
                //     if (error.status == 412) {

                //       alert(error.error.msg)

                //     }
                //   })

                /********************************************* */
            },
                error => {
                    if (error.status == 401) {

                        alert(error.error.detail)
                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        alert(error.error.msg)
                        return
                    }
                })
        } else {
            this.flag = false;
        }
    }
    edit_loadNotificationType() {
        this._ser.getNotificationType().subscribe(res => {
            this.arrNotificationType = res;
            return this.arrNotificationType
        },
        error => {
            if (error.status == 401) {
                //this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                alert(error.error.detail)
                this._r.navigate(['/login']);
            }
            if (error.status == 412) {
                //this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
                alert(error.error.msg)
                return
            }
        })
    }

    edit_loadCities(states) {
        var state = states;
        var starray = [];

        for (let i = 0; i < state.length; i++) {
            starray.push(state[i].trim())
        }
        this._ser.getStateList().subscribe(res => {
            this.arrStates = res;
            var i

            var ar1 = []

            if (starray.includes('all')) {
                //starray = this.arrStates
                ar1 = ['all'];
            }else{
                for (i = 0; i < this.arrStates.length; i++) {
                
                    if (starray.includes('all')) {
                        ar1.push(this.arrStates[i].name)
                    } else {
                        if (starray.indexOf(this.arrStates[i].name) > -1) {
                            ar1.push(this.arrStates[i].name)
                        }
                    }
                } 
            }

            this.arr = ar1;
            var tu = this.arr
            var data1 = {
                "device_id": localStorage.getItem('device_id'),
                "app_version": "0.1",
                "app_name": localStorage.getItem('app_name'),
                "state_list": tu
            }

            this._ser.getCities(data1).subscribe(res => {
                this.arrCities = res;
            },
                error => {
                    this.arrCities = [];
                    if (error.status == 401) {
                        //alert(error.error.detail)
                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        //alert(error.error.msg)
                        return
                    }
                })
        },
        error => {
            if (error.status == 401) {
                //alert(error.error.detail)
                this._r.navigate(['/login']);
                return
            }
            if (error.status == 412) {
                //alert(error.error.msg)
                return
            }
        })

    }

    edit_loadPl(ppllist) {
        var pl = ppllist;
        var plarray = [];
        //   }
        for (let i = 0; i < pl.length; i++) {
            plarray.push(pl[i].trim())
        }
        this._ser.getPPL().subscribe(res => {
            this.arrPPL = res;
            var i

            var ar1 = []

            for (i = 0; i < this.arrPPL.length; i++) {

                if (plarray.includes('all')) {
                    ar1.push({ 'ppl': this.arrPPL[i].ppl, 'ppl_id': this.arrPPL[i].ppl_id })
                } else {
                    if (plarray.indexOf(this.arrPPL[i].ppl) > -1) {
                        ar1.push({ 'ppl': this.arrPPL[i].ppl, 'ppl_id': this.arrPPL[i].ppl_id })
                    }
                }
            }
            this.ar = ar1;
            var tu = this.ar
            var data1 = {
                "device_id": localStorage.getItem('device_id'),
                "app_version": "0.1",
                "app_name": localStorage.getItem('app_name'),
                "ppl_list": tu
            }
            this._ser.getPL(data1).subscribe(res => {
                this.arrPL = res;
            },
                error => {
                    this.arrPL = ['all'];
                    if (error.status == 401) {  
                        //alert(error.error.detail)
                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        //alert(error.error.msg)
                        return
                    }
                })
        },
            error => {
                if (error.status == 401) {
                    //alert(error.error.detail)
                    this._r.navigate(['/login']);
                    return
                }
                if (error.status == 412) {
                    //alert(error.error.msg)
                    return
                }
            })

    }

    loadCities() {
        this.spinnerService.show();
        var states = $('.statenames').val();
        var starray = [];


        for (let i = 0; i < states.length; i++) {
            //starray.push(states[i])// working code
            var arr = states[i].split(':');
            var t = arr[1].replace(/'/g, "");
            starray.push(t.toString().trim())
        }

        if (starray.includes('all')) {
            //starray = this.arrStates
            starray = ['all'];
        }
        
        var data = {
            "device_id": localStorage.getItem('device_id'),
            "app_version": "0.1",
            "app_name": localStorage.getItem('app_name'),
            "state_list": starray
        }
        this._ser.getCities(data).subscribe(res => {
            this.arrCities = res;
            this.spinnerService.hide();
            return this.arrCities
        },
            error => {
                this.arrCities = [];
                if (error.status == 401) {
                    alert(error.error.detail)
                    this._r.navigate(['/login']);
                    return
                }
                if (error.status == 412) {
                    alert(error.error.msg)
                    return
                }
                this.spinnerService.hide();
            })
    }
    loadPl() {
        this.spinnerService.show();
        var pl = $('.ppllist').val();
        var plarray = [];
        // for(var i=0;i<pl.length;i++){
        //      var arr=JSON.parse(JSON.stringify(pl[i])).split("'");
        //      var arr1=arr[1].split(',');
        //      plarray.push(JSON.parse(arr[1]));
        // }

        for (let i = 0; i < pl.length; i++) {
            //starray.push(states[i])// working code
            var arr = pl[i].split(':');
            var t = arr[1].replace(/'/g, "");
            plarray.push(t.trim())
            //plarray.push(t.toString().trim())
        }
        this._ser.getPPL().subscribe(res => {
            var i

            var ar1 = []
            // plarray=['"Bolt"','"Harrier"']
            /*******************Working Code */
            // for(i=0;i<this.arrPPL.length;i++){  
            //   var t=plarray.includes(this.arrPPL[i].ppl)
            //    if(plarray.includes(this.arrPPL[i].ppl)){
            //     ar1.push({'ppl':this.arrPPL[i].ppl,'ppl_id':this.arrPPL[i].ppl_id})
            //   }
            //   else{
            //     i++;    
            //   }
            // }  
            /****************************************** */

            for (i = 0; i < this.arrPPL.length; i++) {

                if (plarray.includes('all')) {
                    ar1.push({ 'ppl': this.arrPPL[i].ppl, 'ppl_id': this.arrPPL[i].ppl_id })
                } else {
                    if (plarray.indexOf(this.arrPPL[i].ppl) > -1) {
                        ar1.push({ 'ppl': this.arrPPL[i].ppl, 'ppl_id': this.arrPPL[i].ppl_id })
                    }
                }
                // for(j=0;j< plarray.length;j++){
                //   if(plarray[j]==this.arrPPL[i]){
                //   }
                // }
            }
            this.ar = ar1;
            var tu = this.ar
            var data1 = {
                "device_id": localStorage.getItem('device_id'),
                "app_version": "0.1",
                "app_name": localStorage.getItem('app_name'),
                "ppl_list": tu
            }

            this._ser.getPL(data1).subscribe(res => {
                this.arrPL = res;
                this.spinnerService.hide();
            },
                error => {
                    this.arrPL = [];
                    if (error.status == 401) {
                        //alert(error.error.detail)
                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        //alert(error.error.msg)
                        return
                    }
                    this.spinnerService.hide();
                })
        },
            error => {
                if (error.status == 401) {
                    //alert(error.error.detail)
                    this._r.navigate(['/login']);
                    return
                }
                if (error.status == 412) {
                    //alert(error.error.msg)
                    return
                }
                this.spinnerService.hide();
            })
    }
    public myDate: IMyDate = {

        year: this.getCurrentDay('Y'),
        month: this.getCurrentDay('M'),
        day: this.getCurrentDay('D'),
    }


    getCurrentDay(cat): any {
        let output;
        var today = new Date();
        var day = today.getDate();
        if (day != 1) {
            day = day - 1;
        }

        var month = today.getMonth() + 1; //January is 0!
        var year = today.getFullYear();

        if (cat === 'D') {
            output = day;
        }
        else if (cat === 'M') {
            output = month;
        }
        else {
            output = year;
        }
        return output;
    }
    // getFiles(event) {
    //     this.files1 = event.currentTarget.files;
    //     var i;

    //     for (i = 0; i < this.files1.length; i++) {

    //         // if (this.files[i].type != 'image/png') {
    //         //   alert("Image Type is not valid")
    //         //   //this.refreshPage();
    //         //   return;
    //         // }
    //     }

    //     var width = event.currentTarget.offsetWidth;
    //     var imgheight = event.currentTarget.offsetHeight;
    // }
    getFiles(event) {
        this.files = event.target.files;
        var i;
        if (this.files != undefined && this.files != null) {
            for (i = 0; i < this.files.length; i++) {
                if (this.files[i].type != 'image/png' && this.files[i].type != 'image/jpg' && this.files[i].type != 'image/jpeg') {
                    alert("Only png, jpg and jpeg files are allowed!")
                    return;
                }
            }
        }
    }
    //(keypress)="omit_special_char($event)" (paste)="omit_special_char($event)"
    omit_special_char(event)
    {   
        var len;
        var k;  
        if(event.type == "paste"){
            let clipboardData = event.clipboardData;
            let pastedText = clipboardData.getData('text');
            for (let i = 0; i < pastedText.length; i++) {
                k = pastedText.charCodeAt(i);
                if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)){
                    
                }else{
                    return false;
                }
            }
            return true;
        }else{
            k = event.charCode;  //         k = event.keyCode;  (Both can be used)
            return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
        } 
    }

    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        markCurrentDay: true,
        disableUntil: {year: this.getCurrentDay('Y'), month: this.getCurrentDay('M'), day: this.getCurrentDay('D')}
    };
    refreshPage() {
        this.files = null;
        this.form.nativeElement.reset();
        return
    }
    addNotification(data) {

        //console.log(JSON.stringify(data));
        // for (let j = 0; j < this.uploader.queue.length; j++) {
        //   let data = new FormData();
        //   let fileItem = this.uploader.queue[j]._file;
        //   data.append('file', fileItem);
        //   data.append('fileSeq', 'seq'+j);
        //  // data.append( 'dataType', this.uploadForm.controls.type.value);
        //   //this.uploadFile(data).subscribe(data => alert(data.message));
        // }
        var state = $('.statenames').val();
        var city = $('.citylist').val();
        var ppl = $('.ppllist').val();
        var pl = $('.pllist').val();
        var pplarray = [];
        var imgs = [];

        // for(var i=0;i<pl.length;i++){
        // }
        // for(var i=0;i<ppl.length;i++){
        //    var arr=ppl[i].split(':');
        //    var ppl1=arr[0].split(':')
        //    var t=ppl1[1].replace(/"/g,"");
        //   pplarray.push(t.toString().trim())
        // }
        var ppl = $('.ppllist').val();
        var pplarray = [];
        for (var i = 0; i < ppl.length; i++) {
            var arr = JSON.parse(JSON.stringify(ppl[i])).split("'");
            var arr1 = arr[1].split(':');
            var t = arr[1].replace(/"/g, "");
            pplarray.push(t.toString().trim())
            // var pp=JSON.parse(arr[1]);
            //pplarray.push(t);
        }

        // var pplarray = [];
        // for (i = 0; i < this.arrPPL.length; i++) {
        //     if (ar1.includes('all')) {
        //         pplarray.push(this.arrPPL[i].ppl.toString().trim())
        //     } else {
        //         if (ar1.indexOf(this.arrPPL[i].ppl) > -1) {
        //             pplarray.push(this.arrPPL[i].ppl.toString().trim())
        //         }
        //     }
        // }
        var plarray = [];
        for (let i = 0; i < pl.length; i++) {
            //starray.push(states[i])// working code
            var arr = pl[i].split(':');
            var t = arr[1].replace(/'/g, "");
            plarray.push(t.toString().trim())
        }
        var states = $('.statenames').val();
        var starray = [];
        for (let i = 0; i < states.length; i++) {
            //starray.push(states[i])// working code
            var arr = states[i].split(':');
            var t = arr[1].replace(/'/g, "");
            starray.push(t.toString().trim())
        }

        // var starray = [];
        // for (i = 0; i < this.arrStates.length; i++) {
        //     if (ar2.includes('all')) {
        //         starray.push(this.arrStates[i].name)
        //     } else {
        //         if (ar2.indexOf(this.arrStates[i].name) > -1) {
        //             starray.push(this.arrStates[i].name)
        //         }
        //     }
        // }
        var notificationType = $('.notificationType').val();
        var ctarray = [];
        for (let i = 0; i < city.length; i++) {
            //starray.push(states[i])// working code
            var arr = city[i].split(':');
            var t = arr[1].replace(/'/g, "");
            ctarray.push(t.toString().trim())
        }
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        var today = new Date();
        var latest_date = this.datepipe.transform(today, 'MM/dd/yyyy');
        // Comment by Rahul
        // if(data['notification_category']==''){
        //   this.message="Enter Notification Category"

        //   // $('#caterror').text("Enter Notification Category")
        //   this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        //   return
        // }else{
        //   $('.displayerror').text("")
        // }
        if (data['notification_title'] == '' || data['notification_title'] == undefined) {
            this.message = "Enter Notification Title"
            // $('.displayerror').text("Enter Notification Title")
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else {
            $('.displayerror').text("")
        }
        if (notificationType == '' || notificationType == undefined) {
            this.message = "Select Notification Type"
            // $('.displayerror').text("Enter Notification Type")
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else {
            $('.displayerror').text("")
        }
        if (data['notification_desc'] == '') {
            this.message = "Enter Notification Description"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }else if (data['notification_startDate'] == undefined || data['notification_startDate'] == '') {
            this.message = "Select Notification From Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else if ((new Date(latest_date)) > (new Date(data['notification_startDate'].formatted))) {
            //alert("From Date must be greater than Current Date" )
            this.message = "From date must be greater than Current Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

            return;
        } else if (data['notification_endDate'] == undefined || data['notification_endDate'] == '') {
            this.message = "Select Notification To Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else if (new Date(this.jc_to_date) < new Date(this.jc_from_date)) {
            // else if(new Date(data['notification_endDate'].formatted) < new Date(data['notification_startDate'].formatted)){
            // flag=0;
            this.message = "To date must be greater than From Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }else if ((new Date(latest_date)) > (new Date(data['notification_endDate'].formatted))) {
            // alert("To Date must be greater than Current Date" ) 
            this.message = "To date must be greater than Current Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

            return
        }
        else if (data['kms_run_operator'] == '') {
            this.message = "Please Select KMS Run"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (data['kms_run'] == '') {
            this.message = "Please enter KMS"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (state == '') {
            this.message = "Please Select State"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (city == '') {
            this.message = "Please Select City"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }

        /*******************Validations *********************/

        if (ppl == '') {
            this.message = "Please Select PPL"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        if (pl == '') {
            this.message = "Please Select PL"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }

        /******************************************** */
        var ppl = $('.ppllist').val();
        if (data['notification_endDate'].formatted == undefined) {
            data['notification_endDate'] = this.jc_to_date + ' 23:59:59'

        } else {
            data['notification_endDate'] = data['notification_endDate'].formatted + ' 23:59:59';
        }
        if (data['notification_startDate'].formatted == undefined) {
            data['notification_startDate'] = this.jc_from_date + ' 00:00:01'
        } else {
            data['notification_startDate'] = data['notification_startDate'].formatted + ' 00:00:01';
        }
        data['app_name'] = localStorage.getItem('app_name');
        // data['notification_type']='Information';
        // data['notification_category']='Launch';
        data['notification_base_category'] = 'Information';
        data['portal_app_name'] = 'customersocialapp-portal';
        data['app_version'] = '0.1';
        data['device_id'] = localStorage.getItem('device_id');
        data['state'] = starray.toString();
        data['city'] = ctarray.toString();
        // data['ppl']='all';
        data['ppl'] = pplarray.toString();
        data['pl'] = plarray.toString();;
        data['is_chassis_flag'] = false
        data['all_cities_flag'] = false
        // data['is_active'] = true
        data['chassis_list'] = ''
        data['jdp_flag'] = 'Y';
        var img1
        let formData: FormData = new FormData();
        if (this.files != undefined && this.files.length != undefined) {
            for (i = 0; i < this.files.length; i++) {
                if (this.files[i].type != 'image/png' && this.files[i].type != 'image/jpg' && this.files[i].type != 'image/jpeg') {
                    alert("Only png, jpg and jpeg files are allowed!")
                    return;
                }else{
                    formData.append('banner_images', this.files[i]);
                }
            }

        }
        // if (this.files1 != undefined && this.files1 != null) {
        //     img1 = this.files1[0];
        //     formData.append('banner_images', img1)
        //     imgs.push(this.files1[0])

        //     // data['banner_images[]']= imgs
        //     // data['banner_images']=this.files1[0];
        // }
        // else if(this.files1 == undefined && this.files1 == null && this._ar.snapshot.params.id == undefined){
        //   img1=''
        // }
        // else if(this.files1 == undefined && this.files1 == null && this._ar.snapshot.params.id != undefined){
        //   img1=this.ban_img;
        // }


        /********************************************************* */



        formData.append('app_name', localStorage.getItem('app_name'));
        formData.append('portal_app_name', "customersocialapp-portal");
        formData.append('notification_id', this._ar.snapshot.params.id);
        if (data['city'] == 'all') {
            formData.append('all_cities_flag', 'true');
        } else {
            formData.append('all_cities_flag', 'false');
        }

        formData.append('app_version', "0.1")
        formData.append('chassis_list', '')
        // formData.append('city',"all")
        formData.append('city', data['city'])
        //formData.append('pl', "all")
        formData.append('pl', data['pl'])

        // formData.append('ppl', "all")
        formData.append('ppl', data['ppl'])

        // formData.append('state', "all")
        formData.append('state', data['state'])

        formData.append('device_id', localStorage.getItem('device_id'))
        // formData.append('is_active', data['is_active'])
        formData.append('is_chassis_flag', 'false')
        formData.append('jdp_flag', "Y")
        formData.append('kms_run', data['kms_run'])
        formData.append('kms_run_operator', data['kms_run_operator'])
        formData.append('notification_base_category', "Information")
        // formData.append('notification_category', data['notification_category']) // Comment by Rahul
        formData.append('notification_desc', data['notification_desc'])
        formData.append('notification_endDate', data['notification_endDate'])

        formData.append('notification_startDate', data['notification_startDate'])
        formData.append('notification_title', data['notification_title'])
        formData.append('notification_type', notificationType)
        formData.append('ready_to_publish', data['ready_to_publish'])
        formData.append('is_active', data['is_active'])
        console.log(JSON.stringify(data));
        /******************************************************* */
        if (this._ar.snapshot.params.id == undefined) {
            this._ser.addNotificationServ(formData).subscribe(res => {
                this.res = JSON.parse(JSON.stringify(res));
                this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);
                this._r.navigate(['/notification']);
                //return res

            },
                error => {
                    if (error.status == 401) {

                        // alert(error.error.detail)
                        this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                        // alert(error.error.msg )
                        this._r.navigate(['/login']);
                    }

                })
        } else {
            this.spinnerService.show();
            data['notification_id'] = this._ar.snapshot.params.id;

            this._ser.update(formData).subscribe(
                res => {
                    this.res = JSON.parse(JSON.stringify(res));
                    this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);

                    // this.setResult(res);
                    this._r.navigate(['/notification'])
                },
                error => {
                    if (error.status == 401) {
                        this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                        // alert(error.error.detail)
                        // this._r.navigate(['/login']);
                        this.setlogout()
                    }
                    if (error.status == 412) {
                        this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

                        // this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

                        // alert(error.error.msg)
                        //this._r.navigate(['/login']);
                    }
                    //this.setResult(error);
                }
            );
            this.spinnerService.hide();
        }
    }
    setResult(res) {
        this.res = res;
        $("#msgModal").modal('show');
        // $("#msgModal").fadeIn(3000, function() {
        //   this.closeModel();
        // });
        // $('#msgModal').model('show');
    }
    setlogout() {
        localStorage.setItem('access_token', '');
        localStorage.setItem('device_id', '');
        this._r.navigate(['/login']);
        return
    }

}

