import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Type } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class BaseapiService {
  //baseUrl: string = 'https://eguruskindev.api.tatamotors/api/customer-app/';//Sandbox
  //baseUrl: string = 'https://skindevreplica-tls.api.tatamotors/api/customer-app/';//Sandbox Python3
  //baseUrl: string = 'https://skinprod.api.tatamotors/api/customer-app/';//Prod Python3
  // baseUrl: string = 'https://skindev-pv.api.tatamotors/api/customer-app/';//Dev PV Sub
  baseUrl: string = 'https://skinprod-pv.api.tatamotors/api/customer-app/';//Prod PV Sub
  res
   device_id=Math.floor(Math.random()*90000000) + 10000000;
  constructor(private _http:HttpClient) { }
  
  private urlSource = new BehaviorSubject('false');
  isUrlSet = this.urlSource.asObservable();
 
 
  changeUrl(url: string) {
    this.urlSource.next(url)
  }
  
  login(data){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let options = { headers: headers };    
    return this._http.post(this.baseUrl+"user/login/ ", data, options).pipe(map((res) => {
      return res;
    }))
  }
  getPPL(){
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    var cdata={"device_id":localStorage.getItem('device_id'),
    "app_version":"0.1",
    "app_name":  localStorage.getItem('app_name'),
    }
    let options = { headers: headers};
    return this._http.post(this.baseUrl+"vehicle/ppl/", cdata, options).pipe(map((res) => {
      return res;
    }))
  }
  getGenericFeedback(data){
 
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
	  'Authorization':'Bearer '+localStorage.getItem('access_token')
 });
  let options = { headers: headers };
    return this._http.post(this.baseUrl+"feedback/portal/generic/view-list/",data,options).pipe(map((res)=>{
      return res;
    }))
  }
  getPL(data){
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    
    let options = { headers: headers };
    return this._http.post(this.baseUrl+"vehicle/pl-by-ppl/", data, options).pipe(map((res) => {
      return res;
    }))

  }
  getStateList(){
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    var data={"device_id":localStorage.getItem('device_id'),
    "app_version":"0.1",
    "app_name": localStorage.getItem('app_name'),
    
    }
    let options = { headers: headers };
    return this._http.post(this.baseUrl+"states/", data,options).pipe(map((res) => {
    return res;
    }))
  }
  getNotificationType(){
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    var data={"device_id":localStorage.getItem('device_id'),
    "app_version":"0.1",
    "app_name": localStorage.getItem('app_name'),
    }
    let options = { headers: headers };
    return this._http.post(this.baseUrl+"notifications/portal/view-type/", data,options).pipe(map((res) => {
      return res;
      //console.log(res)
    }))
  }
  getNotification(data){
    let headers = new HttpHeaders({
    'Content-Type': 'application/json',
	  'Authorization':'Bearer '+localStorage.getItem('access_token')
 });
 var cdata={
   "device_id":localStorage.getItem('device_id'),
    "app_version":"0.1",
    "app_name": localStorage.getItem('app_name'),
    }
  let options = { headers: headers};
    return this._http.post(this.baseUrl+"notifications/portal/view-details/",data,options).pipe(map((res)=>{
      return res;
    }))
  }
  getCities(data){
    var headers = new HttpHeaders({
       'Content-Type':'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('access_token')
     });
     var cdata={"device_id":localStorage.getItem('device_id'),
    "app_version":"0.1",
     "app_name": localStorage.getItem('app_name'),
    }
     let options = { headers: headers };
     return this._http.post(this.baseUrl+"dealer/city-from-state-list/", data, options).pipe(map((res) => {
       return res;
     }))
   }
   addNotificationServ(data){
    //  return data;
    var headers = new HttpHeaders({
      // 'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    
    let options = { headers: headers };
    return this._http.post(this.baseUrl+"/notifications/portal/add-details/", data,options).pipe(map((res) => {
      return res;
      //console.log(res)
    }))
   }
   getUser(data){
     //console.log(id)
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let options = { headers: headers };
    
      return this._http.post(this.baseUrl+"/notifications/portal/view-details/id",data,options).pipe(map((res)=>{
      return res;
    }))
   }
   userData(data){
    var headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    let options = { headers: headers };
    return this._http.post(this.baseUrl+"/notifications/portal/view-details/id/", data,options).pipe(map((res) => {
    return res;
      //console.log(res)
    }))

   }
   update(data){
     console.log("In Base Api")
     //console.log(data)
    var headers = new HttpHeaders({
       // 'Content-Type':'application/x-www-form-urlencoded',
      //'Content-Type':'application/json',
      //'Content-Type':'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    
    let options = { headers: headers };
    // return data
// console.log(data)
    return this._http.post(this.baseUrl+"notifications/portal/edit-details/",data,options).pipe(map((res)=>{
      return res;
    }))
    
  }

  getPsf(data){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
	  'Authorization':'Bearer '+localStorage.getItem('access_token')
     });
  let options = { headers: headers };
    return this._http.post(this.baseUrl+"feedback/psf/portal/view/",data,options).pipe(map((res)=>{
      return res;
    }))
  }
}
