import { Component, OnInit } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router } from '@angular/router';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  title='';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  constructor(private _ser:BaseapiService, private _r:Router,
              private idle: Idle, private keepalive: Keepalive) 
  { 
      // sets an idle timeout of 20 mins.
    idle.setIdle(1200);
    // sets a timeout period of 60 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      localStorage.setItem('access_token','');
      localStorage.setItem('device_id','');
      this._ser.changeUrl('false');
      this._r.navigate(['/login']);
    });
    idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    idle.onTimeoutWarning.subscribe((countdown) => this.idleState = 'You will time out in ' + countdown + ' seconds!');

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
    
  }

  reset() {
    this.idle.watch();
    this.idleState = '';
    this.timedOut = false;
  }
  logout(){
    var con =confirm("Do you want to Logout?")
    // alert()
    if (con === true){
      //alert("gggg")
      localStorage.setItem('access_token','');
      localStorage.setItem('device_id','');
      this._ser.changeUrl('false');
      this._r.navigate(['/login']);
    }else{
      
      // this._r.navigate(['/postservicefeedback']);
      this._ser.changeUrl('true');
        this._r.navigate(['/notification'])
    }
    // console.log(localStorage.getItem("device_id"))
    // else{
    //   alert("HHHH");
    //   return
    //   // this._r.navigate(['/notification']); 
    //   //alert("Hiii")
    // }
    
    // this.logout();
  }
  ngOnInit() {
    //this.username=localStorage.getItem('username');
    if(localStorage.getItem('app_name')=='com.tatamotors.cvcustomercare'){
     this.title="Commercial Vehicle" ;
    }
    else if(localStorage.getItem('app_name')=='com.ttl.customersocialapp'){
      this.title="Passenger Vehicle" ;
    }
   
    //this.title=localStorage.getItem('username');


  }

}
