import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
import { MatPaginator, MatTableDataSource, MatInput, MatButtonModule } from '@angular/material';
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormControl } from '@angular/forms';
//import { MatFileUploadModule, } from 'angular-material-fileupload';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as $ from "jquery";
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material';
import { DatePipe } from '@angular/common'
// import { validateConfig } from '@angular/router/src/config';


@Component({
    selector: 'app-add-notification-chassis',
    templateUrl: './add-notification-chassis.component.html',
    styleUrls: ['./add-notification-chassis.component.css']
})
export class AddNotificationChassisComponent implements OnInit {
    submitted = false;
    @ViewChild('form', {static: true}) form: any
    notifyChassisForm: FormGroup;
    res
    files: FileList;
    files1: FileList;
    imgs = [];
    globalData: any[] = [];
    //  globalData:any[]
    bbbb;
    c;
    message: string = 'Snack Bar opened.';
    //actionButtonLabel: string = 'Retry';
    actionButtonLabel: string = '';
    action: boolean = true;
    setAutoHide: boolean = true;
    autoHide: number = 2000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    addExtraClass: boolean = false;
    //  jc_from_date: any = 'yyyy-mm-dd';
    //  jc_to_date: any = 'yyyy-mm-dd';
    jc_from_date: any = '';
    jc_to_date: any = '';
    // date = new FormControl(new Date());
    // notification_endDate = new FormControl((new Date()).toISOString());

    constructor(private _ser: BaseapiService, private _r: Router, el: ElementRef, private _fb: FormBuilder, private _ar: ActivatedRoute, public datepipe: DatePipe, public snackBar: MatSnackBar) {
        this.notifyChassisForm = this._fb.group({
            // FullName:['',[Validators.required,Validators.pattern("[a-zA-Z]{3,}[ ]{1}[a-zA-Z]{3,}")]],
            // Email:['',[Validators.required,Validators.pattern("[a-zA-Z0-9]{3,}[@]{1}[a-zA-Z0-9]{3,}[.]{1}[a-zA-Z0-9]{2,}")]],
            // MobileNo:['',[Validators.required,Validators.pattern("[0-9]{10}")]],
            // State:['',[Validators.required]],

            notification_title: ['', []],
            notification_type: ['', []],

            //   notification_category:['',[]], //Commented by Rahul
            notification_desc: ['', []],
            notification_startDate: ['', []],
            notification_endDate: ['', []],
            kms_run_operator: ['', []],
            kms_run: ['', []],
            state: ['', []],
            // city:['',[]],
            // ppl:['',[]],
            pl: ['', []],
            banner_images: ['', []],
            chassis_file: ['', []],
            chassis_file_path: ['', []],
            // banner_images:[[],[]],
            addDynamicElement: this._fb.array([]),
            chassis_txtfile: ['', []],
            ready_to_publish: ['false', []],
            is_active: ['false', []],
        })
    }
    file
    ch_list;
    flag = false;
    ban_img
    chassislist


    ngOnInit() {
        var today = new Date();
        // let date = new Date();
        //     this.notifyChassisForm.patchValue({notification_endDate: {
        //     date: {
        //         year: date.getFullYear(),
        //         month: date.getMonth() + 1,
        //         day: date.getDate()}
        //     }});
        $('.notificationType').select2(
            { tags: true }
        )
        this._ser.getNotificationType().subscribe(res => {
            this.arrNotificationType = res;
            return this.arrNotificationType
        },
        error => {
            if (error.status == 401) {
                let config = new MatSnackBarConfig();
                config.verticalPosition = this.verticalPosition;
                config.horizontalPosition = this.horizontalPosition;
                config.duration = this.setAutoHide ? this.autoHide : 0;
                this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                this._r.navigate(['/login']);
                return
                //this._r.navigate(['/login']);
            }
        })

        if (this._ar.snapshot.params.id != undefined) {

            this.flag = true;

            const id = this._ar.snapshot.params.id;

            var data = {
                "app_name": localStorage.getItem('app_name'),
                "portal_app_name": "customersocialapp-portal",
                "app_version": "0.1",
                "device_id": localStorage.getItem('device_id'),
                "id": id
            }

            this._ser.userData(data).subscribe(res => {
                this.flag = true;

                var notification_endDate = this.datepipe.transform(res['notification']['notification_endDate'], 'MM/dd/yyyy');
                var start_date = this.datepipe.transform(res['notification']['notification_startDate'], 'MM/dd/yyyy');
                let date = new Date();
                this.notifyChassisForm.patchValue({
                    notification_endDate: {
                        date: notification_endDate


                        // date: {
                        //     year: date.getFullYear(),
                        //     month: date.getMonth() + 1,
                        //     day: date.getDate()}
                    }

                });
                this.jc_from_date = start_date;
                this.jc_to_date = notification_endDate;
                this.notifyChassisForm.patchValue({ notification_startDate: { date: start_date } });
                //this.notifyChassisForm.controls['notification_endDate'].patchValue({date:'11/07/2021'});

                this.notifyChassisForm.controls['notification_type'].patchValue(res['notification']['notification_type']);
                //   this.notifyChassisForm.controls['notification_category'].patchValue(res['notification']['notification_category']); //Commented by Rahul
                this.notifyChassisForm.controls['notification_title'].patchValue(res['notification']['notification_title']);
                this.notifyChassisForm.controls['notification_desc'].patchValue(res['notification']['notification_desc']);
                this.notifyChassisForm.controls['kms_run'].patchValue(res['notification']['kms_run']);
                this.notifyChassisForm.controls['kms_run_operator'].patchValue(res['notification']['kms_run_operator']);
                this.ban_img = res['notification']['banner_images']
                this.chassislist = res['notification']['chassis_file_path']
                if (res['notification']['ready_to_publish'] == true) {
                    this.notifyChassisForm.controls['ready_to_publish'].patchValue('true');
                } else {
                    this.notifyChassisForm.controls['ready_to_publish'].patchValue('false');
                }

                if (res['notification']['is_active'] == true) {
                    this.notifyChassisForm.controls['is_active'].patchValue('true');
                } else {
                    this.notifyChassisForm.controls['is_active'].patchValue('false');
                }
                /********************************************* */
            },
                error => {
                    if (error.status == 401) {

                        //alert(error.error.detail)
                        this._r.navigate(['/login']);
                    }
                    if (error.status == 412) {
                        //alert(error.error.msg)
                    }
                })
        } else {
            this.flag = false;
        }


    }
    arrNotificationType;
    public myDate: IMyDate = {

        year: this.getCurrentDay('Y'),
        month: this.getCurrentDay('M'),
        day: this.getCurrentDay('D'),
    }


    getCurrentDay(cat): any {
        let output;
        var today = new Date();


        var day = today.getDate();
        if (day != 1) {
            day = day - 1;
        }

        var month = today.getMonth() + 1; //January is 0!
        var year = today.getFullYear();

        if (cat === 'D') {
            output = day;
        }
        else if (cat === 'M') {
            output = month;
        }
        else {
            output = year;
        }
        return output;
    }
    public myDatePickerOptions: IMyDpOptions = {
        dateFormat: 'mm/dd/yyyy',
        markCurrentDay: true,
        disableUntil: {year: this.getCurrentDay('Y'), month: this.getCurrentDay('M'), day: this.getCurrentDay('D')}
    };

    feedback_from_date: any = 'yyyy-mm-dd';
    feedback_to_date: any = 'yyyy-mm-dd';


    onJcFromDateChanged(event: IMyDateModel) {
        if (event.formatted != '') {
            this.jc_from_date = event.formatted;
        } else {
            this.jc_from_date = '';
        }
    }
    onJcToDateChanged(event: IMyDateModel) {
        if (event.formatted != '') {
            this.jc_to_date = event.formatted;
        } else {
            this.jc_to_date = '';
        }
    }
    // onJcFromDateChanged(event: IMyDateModel) {
    // 	this.jc_from_date = event.formatted;
    //   }
    // onJcToDateChanged(event: IMyDateModel) {

    //   if(event.formatted){
    //     this.jc_to_date = event.formatted;
    //   }

    // } 

    view_notification(data) {
    }
    fileChanged(e) {
        this.file = e.target.files;
        var i;
        if (this.file != undefined && this.file != null) {
            for (i = 0; i < this.file.length; i++) {
                if (this.file[i].type != 'text/plain') {
                    alert("Only txt file allowed!")
                    return;
                }
            }
        }
    }
    downloadchassis() {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', this.chassislist);
        link.setAttribute('download', `chassis.txt`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // for (var i: any = 0; i < this.chassislist.length; i++) {
        //     let jsonObj2 = {};
        //     if (this.chassislist[i] != '' && this.chassislist[i] != undefined) {
        //         jsonObj2['Chassis Num'] = this.chassislist[i];
        //     }
        //     this.globalData.push(jsonObj2)
        //     // 
        // }

        // var chassis = [{ notification_id: 59, app_name: "com.ttl.customersocialapp" }]
        // var ws = XLSX.utils.json_to_sheet(this.globalData);
        // var wb = XLSX.utils.book_new(); XLSX.utils.book_append_sheet(wb, ws, "PSF Dealer Data");
        // XLSX.writeFile(wb, "Chassis List.xlsx");
    }
    getFiles(event) {
        this.files1 = event.target.files
        var i;
        if (this.files1 != undefined && this.files1 != null) {
            // var fileSize = this.files1[0].size;
            // var img_name = this.files1[0].name;
            // if (/\s/.test(img_name)) {
            //     alert('Plaese upload image having no space in Name');
            // }
            // if (fileSize >= 5242880) {
            //     alert('Please select a file below 5MB');
            //     //this.refreshPage();
            // }
            for (i = 0; i < this.files1.length; i++) {
                if (this.files1[i].type != 'image/png' && this.files1[i].type != 'image/jpg' && this.files1[i].type != 'image/jpeg') {
                    alert("Only png, jpg and jpeg files are allowed!")
                    return;
                }
            }
        }
    }

    //(keypress)="omit_special_char($event)" (paste)="omit_special_char($event)"
    omit_special_char(event)
    {   
        var len;
        var k;  
        if(event.type == "paste"){
            let clipboardData = event.clipboardData;
            let pastedText = clipboardData.getData('text');
            for (let i = 0; i < pastedText.length; i++) {
                k = pastedText.charCodeAt(i);
                if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)){
                    
                }else{
                    return false;
                }
            }
            return true;
        }else{
            k = event.charCode;  //         k = event.keyCode;  (Both can be used)
            return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
        } 
    }

    addNotification(data) {
        var a;
        if (this.file != undefined) {
            a = this.addNotification_upload(data)
        } else {
            a = this.addNotification_withoutChassis(data)
        }

        // var i = a.then((val) => {return val});
    }
    addNotification_withoutChassis(data) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        var notificationType = $('.notificationType').val();
        let formData: FormData = new FormData();
        let fileReader = new FileReader();
        var today = new Date();
        var latest_date = this.datepipe.transform(today, 'MM/dd/yyyy');
        //Commented by Rahul
        //   if(data['notification_category']==''){
        //     this.message="Enter Notification Category"
        //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        //     return
        //   }else 
        if (notificationType == '' || notificationType == undefined) {
            this.message = "Select Notification Type"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else if (data['notification_title'] == '' || data['notification_title'] == undefined) {
            this.message = "Enter Notification Title"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (data['notification_desc'] == '') {
            this.message = "Enter Notification Description"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }else if (data['notification_startDate'] == undefined || data['notification_startDate'] == '') {
            this.message = "Select Notification From Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }else if ((new Date(latest_date)) > (new Date(this.jc_from_date))) {
            this.message = "From Date must be greater than Current Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return;
        }
        else if (data['notification_endDate'] == undefined || data['notification_endDate'] == '') {
            this.message = "Select Notification To Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (new Date(this.jc_to_date) < new Date(this.jc_from_date)) {
            this.message = "To date must be greater than From Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);

            return
        }
         else if ((new Date(latest_date)) > (new Date(this.jc_to_date))) {
            this.message = "To Date must be greater than Current Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        // return
        formData.append('chassis_list', this.chassislist);
        formData.append('app_name', localStorage.getItem('app_name'));
        formData.append('portal_app_name', "customersocialapp-portal");
        // formData.append('notification_id',this._ar.snapshot.params.id);
        formData.append('all_cities_flag', 'false');
        formData.append('app_version', "0.1")
        formData.append('city', "all")
        formData.append('device_id', localStorage.getItem('device_id'))
        formData.append('is_chassis_flag', 'true')
        formData.append('jdp_flag', "Y")
        formData.append('kms_run', data['kms_run'])
        formData.append('kms_run_operator', data['kms_run_operator'])
        formData.append('notification_base_category', "Information")
        // formData.append('notification_category', data['notification_category']) //Commented by Rahul
        formData.append('notification_desc', data['notification_desc'])
        //data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
        //formData.append('notification_endDate',data['notification_endDate'].formatted+' 23:59:59');
        ///data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';

        if (data['notification_endDate'].formatted == undefined) {
            data['notification_endDate'] = this.jc_to_date + ' 23:59:59'

        } else {
            data['notification_endDate'] = data['notification_endDate'].formatted + ' 23:59:59';
        }
        if (data['notification_startDate'].formatted == undefined) {
            data['notification_startDate'] = this.jc_from_date + ' 00:00:01'
        } else {
            data['notification_startDate'] = data['notification_startDate'].formatted + ' 00:00:01';
        }

        formData.append('notification_endDate', data['notification_endDate'])
        formData.append('notification_startDate', data['notification_startDate'])
        formData.append('notification_title', data['notification_title'])
        formData.append('notification_type', notificationType)
        formData.append('pl', "all")
        formData.append('ppl', "all")
        formData.append('ready_to_publish', data['ready_to_publish'])
        formData.append('is_active', data['is_active'])
        formData.append('state', "all")
        if (this.files1 != undefined && this.files1.length != undefined) {
            for (let i = 0; i < this.files1.length; i++) {
                if (this.files1[i].type != 'image/png' && this.files1[i].type != 'image/jpg' && this.files1[i].type != 'image/jpeg') {
                    alert("Only png, jpg and jpeg files are allowed!")
                    return;
                }else{
                    formData.append('banner_images', this.files1[i]);
                }
            }
        }
        if (this._ar.snapshot.params.id == undefined) {
            // if (this.files1 != undefined) {
            //     formData.append('banner_images', this.files1[0])
            // }
            // else{
            //   formData.append('banner_images','')

            // }
            this._ser.addNotificationServ(formData).subscribe(res => {
                this.snackBar.open(res['msg'], this.action ? this.actionButtonLabel : undefined, config);
                this._r.navigate(['/notification']);
                return
            },
                error => {
                    if (error.status == 401) {

                        this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                        this._r.navigate(['/login']);
                        return
                    }
                    if (error.status == 412) {
                        this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                        this._r.navigate(['/login']);
                        return
                        //this._r.navigate(['/login']);
                    }
                })
        } else {
            // if (this.files1 != undefined) {
            //     formData.append('banner_images', this.files1[0])
            // }
            var imag = []
            formData.append('notification_id', this._ar.snapshot.params.id);
            data['notification_id'] = this._ar.snapshot.params.id;
            // imag.push(data['banner_images']);
            //data['banner_images']=imag
            //return
            this._ser.update(formData).subscribe(
                res => {
                    this.res = JSON.parse(JSON.stringify(res));
                    this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);

                    this._r.navigate(['/notification']);

                    this.setResult(res);
                    return
                },
                error => {
                    if (error.status == 401) {
                        this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);

                        // this._r.navigate(['/login']);
                        this.setlogout()
                    }
                    if (error.status == 412) {
                        this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                        //this._r.navigate(['/login']);
                    }
                    //this.setResult(error);
                }
            );
        }

    }
    addNotification_upload(data) {
        //   this.submitted = true;
        //   if (this.notifyChassisForm.invalid) {
        //     return;
        // }

        var notificationType = $('.notificationType').val();
        // return
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;


        let formData: FormData = new FormData();
        /*//Commented By Rahul 
        let fileReader = new FileReader();
        var data1;
        var promise
        if (this.file != undefined) {
            var u = fileReader.readAsText(this.file);

            promise = new Promise((resolve, reject) => {
                //TODO
                fileReader.onload = (e) => {
                    // (fileReader.result).replace(/,/g, ", ")
                    resolve(fileReader.result)
                }
                // resolve('done');
            });
        }*/
        /******************************* */

        //Commented by Rahul
        // if (data['notification_category'] == '') {
        //     this.message = "Enter Notification Category"
        //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
        //     return
        // } else 
        if (data['notification_title'] == '' || data['notification_title'] == undefined) {
            this.message = "Enter Notification Title"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        } else if (notificationType == '' || notificationType == undefined) {
            this.message = "Enter Notification Type"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (data['notification_desc'] == '') {
            this.message = "Enter Notification Description"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (data['notification_startDate'] == undefined) {
            this.message = "Enter Notification Start Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }
        else if (data['notification_endDate'] == undefined || data['notification_endDate'] == '') {
            this.message = "Enter Notification End Date"
            this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            return
        }

        if (this.file == undefined) {
            this.snackBar.open('Select Chassis List', this.action ? this.actionButtonLabel : undefined, config);
            return
            // return
        }
        /******************************* */
        //return

        //var b = promise.then((val) => {
            //return val
            //data1 = val;
            // data1.push(val);
            //var arrayImg=[this.files1[0],this.files1[1]]
            if (new Date(data['notification_endDate'].formatted) < new Date(data['notification_startDate'].formatted)) {
                // flag=0;
                this.message = "To date must be greater than Start Date"
                this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            }
            // if(notificationType=='' ||notificationType==undefined ){
            //   this.message="Enter Notification Type"
            //   this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //   return
            //   }else if(data['notification_category']==''){
            //     this.message="Enter Notification Category"
            //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //     return
            //   }else if(data['notification_title']=='' ||data['notification_title']==undefined ){
            //     this.message="Enter Notification Title"
            //     this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //     return
            //   }
            //     else if(data['notification_desc']==''){
            //       this.message="Enter Notification Description"
            //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //       return
            //       }
            //       else if(data['notification_endDate']==undefined|| data['notification_endDate']==''){
            //       this.message="Enter Notification End Date"
            //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //       return
            //       }else if(data['notification_startDate']==undefined){
            //       this.message="Enter Notification Start Date"
            //       this.snackBar.open(this.message, this.action ? this.actionButtonLabel : undefined, config);
            //       return
            //       }
            //       else
            if (this.file == undefined) {
                this.snackBar.open('Upload Chassis List', this.action ? this.actionButtonLabel : undefined, config);
                return
            }


            /********************************************* */
            if (this.file != undefined && this.file.length != undefined) {
                if (this.file[0].type != 'text/plain') {
                    alert("Only txt file allowed!")
                    return false;
                }else{
                    formData.append('chassis_file', this.file[0]);
                    data['chassis_file'] = this.file[0];
                }
            }
            //formData.append('chassis_list', data1);
            formData.append('app_name', localStorage.getItem('app_name'));
            formData.append('portal_app_name', "customersocialapp-portal");
            // formData.append('notification_id',this._ar.snapshot.params.id);
            formData.append('all_cities_flag', 'false');
            formData.append('app_version', "0.1")
            formData.append('city', "all")
            formData.append('device_id', localStorage.getItem('device_id'))
            formData.append('is_chassis_flag', 'true')
            formData.append('jdp_flag', "Y")
            formData.append('kms_run', data['kms_run'])
            formData.append('kms_run_operator', data['kms_run_operator'])
            formData.append('notification_base_category', "Information")
            // formData.append('notification_category', data['notification_category']) //Commented by Rahul
            formData.append('notification_desc', data['notification_desc'])
            //data['notification_endDate']= data['notification_endDate'].formatted+' 23:59:59';
            //formData.append('notification_endDate',data['notification_endDate'].formatted+' 23:59:59');
            ///data['notification_startDate']= data['notification_startDate'].formatted+' 00:00:01';

            if (data['notification_endDate'].formatted == undefined) {
                data['notification_endDate'] = this.jc_to_date + ' 23:59:59'

            } else {
                data['notification_endDate'] = data['notification_endDate'].formatted + ' 23:59:59';
            }
            if (data['notification_startDate'].formatted == undefined) {
                data['notification_startDate'] = this.jc_from_date + ' 00:00:01'
            } else {
                data['notification_startDate'] = data['notification_startDate'].formatted + ' 00:00:01';
            }

            formData.append('notification_endDate', data['notification_endDate'])
            formData.append('notification_startDate', data['notification_startDate'])
            formData.append('notification_title', data['notification_title'])
            formData.append('notification_type', notificationType)
            formData.append('pl', "all")
            formData.append('ppl', "all")
            formData.append('ready_to_publish', data['ready_to_publish'])
            formData.append('is_active', data['is_active'])
            formData.append('state', "all")
            if (this.files1 != undefined && this.files1.length != undefined) {
                for (let i = 0; i < this.files1.length; i++) {
                    if (this.files1[i].type != 'image/png' && this.files1[i].type != 'image/jpg' && this.files1[i].type != 'image/jpeg') {
                        alert("Only png, jpg and jpeg files are allowed!")
                        return;
                    }else{
                        formData.append('banner_images', this.files1[i]);
                    }
                }
            }    
            if (this._ar.snapshot.params.id == undefined) {
                // if (this.files1 != undefined) {
                //     formData.append('banner_images', this.files1[0])
                // } else {
                //     formData.append('banner_images', '')

                // }
                this._ser.addNotificationServ(formData).subscribe(res => {
                    this.snackBar.open(res['msg'], this.action ? this.actionButtonLabel : undefined, config);

                    this._r.navigate(['/notification']);
                    return
                },
                    error => {
                        if (error.status == 401) {
                            this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
                            this._r.navigate(['/login']);
                            return
                        }
                        if (error.status == 412) {
                            this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);

                            this._r.navigate(['/login']);
                            return
                            //this._r.navigate(['/login']);
                        }
                    })
            } else {
                // if (this.files1 != undefined) {
                //     formData.append('banner_images', this.files1[0])
                // }
                var imag = []
                formData.append('notification_id', this._ar.snapshot.params.id);
                data['notification_id'] = this._ar.snapshot.params.id;
                // imag.push(data['banner_images']);
                //data['banner_images']=imag
                //return
                this._ser.update(formData).subscribe(
                    res => {
                        this.res = JSON.parse(JSON.stringify(res));
                        this.snackBar.open(this.res.msg, this.action ? this.actionButtonLabel : undefined, config);

                        this.setResult(res);
                        this._r.navigate(['/notification'])
                    },
                    error => {
                        if (error.status == 401) {
                            this.snackBar.open(error.error.detail, this.action ? this.actionButtonLabel : undefined, config);
                            // this._r.navigate(['/login']);
                            this.setlogout()
                        }
                        if (error.status == 412) {
                            this.snackBar.open(error.error.msg, this.action ? this.actionButtonLabel : undefined, config);
                            //this._r.navigate(['/login']);
                        }
                        //this.setResult(error);
                    }
                );
            }





            /*********************************************** */
        //});

        return;
    }
    //   readTextFile(file){
    //     var data1
    //     let fileReader = new FileReader();
    //      //var c='';
    //     if(fileReader.result!= undefined || fileReader.result!=null){
    //     fileReader.onload = (e) => {
    //       this.c = fileReader.result;
    //       //return fileReader.result;
    //       //data1['chassis_list']= fileReader.result
    //       this.bbbb=fileReader.result;
    //       //data['chassis_list']=fileReader.result
    //       //formData.append('chassis_list123',this.bbbb)
    //     }


    //    }
    //  var u=fileReader.readAsText(file);
    //  //return
    //   }

    setResult(res) {
        this.res = res;
        // $("#msgModal").modal('show');
        // $("#msgModal").fadeIn(3000, function() {
        //   this.closeModel();
        // });
        // $('#msgModal').model('show');
    }
    setlogout() {
        localStorage.setItem('access_token', '');
        localStorage.setItem('device_id', '');
        this._r.navigate(['/login']);
    }
    submitform(files: FileList) {

        let formData: FormData = new FormData();
        if (this.files1 != undefined && this.files1 != null) {
            // formData.append('banner_images', this.files1[0]);
            var i;
            for (i = 0; i < this.files1.length; i++) {
                this.imgs.push(JSON.stringify(this.files1[i]));

            }
            var t: any = this.imgs;
            formData.append('banner_images', t)
            formData.append('app_name', "com.ttl.customersocialapp");
            formData.append('portal_app_name', "customersocialapp-portal");
            formData.append('notification_id', this._ar.snapshot.params.id);
            formData.append('all_cities_flag', 'false');
            formData.append('app_name', "com.ttl.customersocialapp");
            formData.append('app_version', "0.1")
            //banner_images: "C:\fakepath\45-452669_open-walking-man-clipart.png"
            //banner_images[]: [{}]
            formData.append('chassis_txtfile', "")
            formData.append('city', "all")
            formData.append('device_id', "42550570")
            formData.append('is_active', 'true')
            formData.append('is_chassis_flag', 'true')
            formData.append('jdp_flag', "Y")
            formData.append('kms_run', "8000")
            formData.append('kms_run_operator', "1212")
            formData.append('notification_base_category', "Information")
            // formData.append('notification_category', "Launch") //Commented by Rahul
            formData.append('notification_desc', "An update from backend")
            formData.append('notification_endDate', "11/25/2021 23:59:59")
            formData.append('notification_id', "54")
            formData.append('notification_startDate', "05/31/2019 00:00:01")
            formData.append('notification_title', "Notification title")
            formData.append('notification_type', "Information")
            formData.append('pl', "all")
            formData.append('portal_app_name', "customersocialapp-portal")
            formData.append('ppl', "all")
            formData.append('ready_to_publish', "yes")
            formData.append('is_active', "yes")
            formData.append('state', "all")




        }
        this._ser.update(formData).subscribe(res => {
            // return res;
            //$("#msgModal").modal('show');
        },
            error => {
                if (error.status == 401) {

                    alert(error.error.detail)
                    this._r.navigate(['/login']);
                }
            })
    }
}
