import { Component, OnInit } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router } from '@angular/router'
import * as $ from "jquery";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  title='';
  constructor(private _ser:BaseapiService, private _r:Router) { }

  ngOnInit() {
    // alert(window.location.pathname)
    // console.log(this._r);
    // $(document).ready(function(){
    //     alert();
    // })
    // alert()
    if(localStorage.getItem('app_name')=='com.tatamotors.cvcustomercare'){
      this.title="Commercial Vehicle" ;
     }
     else if(localStorage.getItem('app_name')=='com.ttl.customersocialapp'){
       this.title="Passenger Vehicle" ;
     }
    
    
    $('ul.nav li a').on("click",
    function(){
      $('ul.nav li a').removeClass("active1")
      // $(this).css("background-color: #213a89")
     
      $(this).addClass( "active1" );
        // alert()
    })

  }
  logout(){
    //alert(confirm("Do you want to Logout?"))
    if (confirm("Do you want to Logout?")!=false){
      //alert("gggg")

      localStorage.setItem('access_token','');
      localStorage.setItem('device_id','');
      this._ser.changeUrl('false');
      this._r.navigate(['/']);
    }else{
      return
    }
   
   // this.logout();
    }
}
